import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grow,
  CircularProgress
} from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Filters from "./Filters.web";
import ProductsTable from "./ProductsTable.web";
import MainLayout from "../../../components/src/MainLayout.web";
import { classNames } from "../../../components/src/utilities/utils";
import { withTranslation } from 'react-i18next';
import RequestProduct from "./RequestProduct";
import "./catalogue.css";

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

export class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
     // Customizable Area Start
    const isNoProductsFound = (this.state.loading === null && this.state.products.length === 0);
    const { t }= this.props
    return (
      <MainLayout
        id="catalogue"
        navigation={this.props.navigation}
        useSidebar={isNoProductsFound}
        productSearchText={this.state.searchValue}
        cartCount={Object.keys(this.state.cart.products).length}
      >
        {isNoProductsFound ? (
          <>
          <div className="catalogue-no-products-found">{!this.state.searchValue? t("NoProductsFoundInSubCategory"):t("NoProductsFound")}</div>
          <RequestProduct
                isRequestProductLoading={this.state.isRequestProductDialogLoading}
                isRequestProductSubmitted={this.state.isRequestProductDialogSubmitted}
                onFormSubmit={this.onRequestProductFormSubmit}
                onDialogClose={this.onRequestProductDialogClosed}
                isNewProductsLoading={this.state.loading === 'NEW_PRODUCTS'}
              />  
          </>

        ) : (
          <>
            <div className={classNames({
              'products-header': true,
              '__has-search-value': !!this.state.searchValue
            })}>
              <div className="breadcrumb">
                <Button variant="text" onClick={() => this.redirectToLandingPage(false)}>{this.state.categoryName}</Button>
                <ChevronRightIcon />
                <Button variant="text" onClick={() => this.redirectToLandingPage(true)}>{this.state.subCategoryName}</Button>
                <ChevronRightIcon />
                <Typography component="strong">{t("Product")}</Typography>
              </div>
            </div>

            {!this.state.searchValue && (
              <>
                <Typography variant="h1" component="h1" className="page-title">{t("Filter")}</Typography>

                <Filters
                  filterItems={[{ id: 1, name: 'Lorem' }, { id: 2, name: 'Lorem' }]}
                  filterType="MATERIAL"
                  valueIds={this.state.selectedMaterialIds}
                  onItemClicked={this.changeFilterId}
                />

                <Filters
                  filterItems={[{ id: 1, name: 'Lorem' }, { id: 2, name: 'Lorem' }, { id: 3, name: 'Lorem' }]}
                  filterType="DIAMETER"
                  valueIds={this.state.selectedDiameterIds}
                  onItemClicked={this.changeFilterId}
                />

                <Filters
                  filterItems={[{ id: 1, name: 'Lorem' }]}
                  filterType="SCH"
                  valueIds={this.state.selectedSchIds}
                  onItemClicked={this.changeFilterId}
                />
              </>
            )}

            {(this.state.loading === 'INITIAL' || this.state.loading === 'CATALOGUE') ? (
              <div className="products-table-loading">
                <CircularProgress
                  color="primary"
                  style={{ width: 64, height: 64 }}
                />
              </div>
            ) : (
              <ProductsTable
                products={this.state.products}
                cart={this.state.cart}
                isRequestProductLoading={this.state.isRequestProductDialogLoading}
                isRequestProductSubmitted={this.state.isRequestProductDialogSubmitted}
                onFormSubmit={this.onRequestProductFormSubmit}
                onDialogClose={this.onRequestProductDialogClosed}
                productSearchValue={this.state.searchValue}
                onCartItemUpdate={this.updateProductQuantityFromCart}
                fetchCatalogue={this.fetchCatalogue}
                isNewProductsLoading={this.state.loading === 'NEW_PRODUCTS'}
              />          
            )}
          </>
        )}
      </MainLayout>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withTranslation()(Catalogue)
// Customizable Area End
