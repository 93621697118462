import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ICategory, ISubCategory } from './Categories.web';
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { addHashToUrl, isHashExist } from "../../../components/src/utilities/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t:any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  categories: ICategory[],
  sub_categories: ISubCategory[],
  selectedCategoryId: ICategory['id'],
  loading?: boolean;
  isSubCategory:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.onCategorySelected = this.onCategorySelected.bind(this);
    this.onSubCategorySelected = this.onSubCategorySelected.bind(this);
    this.handleFetchCategoriesResponse = this.handleFetchCategoriesResponse.bind(this);
    this.handleValidationResponse = this.handleValidationResponse.bind(this);
    this.handleFetchSubCategoriesResponse = this.handleFetchSubCategoriesResponse.bind(this);
    this.hashListener = this.hashListener.bind(this);

    this.state = {
      categories: [],
      sub_categories: [],
      selectedCategoryId: -1,
      isSubCategory:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      this.setState({
        loading: false
      }, () => {
        this.handleFetchCategoriesResponse(responseJson, apiRequestCallId);
        this.handleValidationResponse(responseJson, apiRequestCallId);
        this.handleFetchSubCategoriesResponse(responseJson, apiRequestCallId);

        if (this.fetchLanguageTextsCallId === apiRequestCallId) {
          MergeEngineUtilities.handleLanguageResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorReponse);
      })
    } else if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(getName(MessageEnum.NavigationData));
      if (data && data.category_id) {
        this.onCategorySelected({ id: data.category_id } as ICategory);
      }

      if (data && data.navigateFrom === 'sidebar') {
        this.setState({
          sub_categories: [],
          selectedCategoryId: -1
        });   
        this.setState({isSubCategory:false})     
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  fetchCategoriesApiCallId: string = '';
  fetchSubCategoriesApiCallId: string = '';
  validationApiCallId: string = '';
  fetchLanguageTextsCallId: string = '';
  fetchNotificationsCallId: string = '';

  handleFetchCategoriesResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.fetchCategoriesApiCallId) {
      if (responseJson && responseJson.data) {
        this.setState({
          categories: responseJson.data.map((item: any) => item.attributes)
        });
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  handleValidationResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.validationApiCallId) {
      if (responseJson && Array.isArray(responseJson.messages) && responseJson.messages[0] && responseJson.messages[0].Token) {
        this.fetchCategories();
      } else {
        MergeEngineUtilities.logout(this.props);
      }
    }
    if (apiRequestCallId === this.fetchNotificationsCallId) {
      if (responseJson?.data?.length === undefined) {
        localStorage.setItem("Notification_count", '0') 
      }else{
        localStorage.setItem("Notification_count",responseJson?.data?.length) 
      }    
    }
  }
 
  handleFetchSubCategoriesResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.fetchSubCategoriesApiCallId) {
      if (responseJson && Array.isArray(responseJson.data)) {
        this.setState({
          sub_categories: responseJson.data.map((item: any) => item.attributes)
        });

        addHashToUrl('categorySelected')
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  async componentDidMount() {
    if (MergeEngineUtilities.isLoggedIn()) {
      this.fetchCategories();
    } else {
      this.validationApiCallId = await MergeEngineUtilities.validateToken(this.props);
    }

    this.fetchLanguageTextsCallId = MergeEngineUtilities.fetchLanguageTexts();
    this.fetchNotificationsCallId = await MergeEngineUtilities.getNotification();
    if (window) {
      window.addEventListener('hashchange', this.hashListener)
    }
  }

  async componentWillUnmount() {
    if (window) {
      window.removeEventListener('hashchange', this.hashListener, true)
    }
  }

  hashListener() {
    const isHashRemoved = !isHashExist('categorySelected')
   
    if (isHashRemoved) {
      this.setState({ selectedCategoryId: -1, sub_categories: [] })
    }
  }

  onCategorySelected(category: ICategory) {
    this.setState({loading: true})
    this.setState({
      selectedCategoryId: category.id
    }, () => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.fetchSubCategoriesApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.fetchSubCategoriesMethod}?category_id=${category.id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({ ...configJSON.apiHeader, token: MergeEngineUtilities._token })
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({isSubCategory:true})
    });
  }

  onSubCategorySelected(subCategory: ISubCategory) {
    const category = this.state.categories.find((cat) => Number(cat.id) === Number(this.state.selectedCategoryId))!;

    MergeEngineUtilities.navigateToScreen('Catalogue', this.props, {
      category_name: category.name,
      category_id: category.id,
      subcategory_name: subCategory.name,
      subcategory_id: subCategory.id,
    })
  }

  fetchCategories() {
    this.setState({
      loading: true
    }, () => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.fetchCategoriesApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.fetchCategoriesMethod}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({ ...configJSON.apiHeader, token: MergeEngineUtilities._token })
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }
  // Customizable Area End
}
