import React from 'react';
import { Button, Link, Typography } from '@material-ui/core';
import Dialog from './Dialog.web';
import { classNames } from './utilities/utils';
import { withTranslation } from 'react-i18next';

const CONTACT_US_EMAILS = [
    'customercare@Pantalone.com',
    'Sales@Pantalone.com',
    'info@Pantalone.com'
];

export class ContactUsDialog extends React.Component<IProps, IState> {
    private dialogRef = React.createRef<Dialog>();
    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
    }
    
    openDialog() {        
        this.dialogRef.current?.openDialog();
    }

    render() {
        const { className = '', useButtonAsAText = false ,t} = this.props;

        return (
            <div className={classNames({
                'contact-us-wrapper': true,
                [className]: !!className
            })}>
                <div className="text-section">
                    <Typography component="span" className="description">
                        {useButtonAsAText && (
                            <Typography
                                color="primary"
                                component="span"
                                className="contact-us-text"
                                onClick={this.openDialog}
                            >
                                {t("ContactUs")}
                            </Typography>
                        )}
                        {this.props.description}
                    </Typography>

                    {!useButtonAsAText && (
                        <Button variant="text" color="primary" onClick={() => this.openDialog()}>
                           {t("ContactUs")}
                        </Button>
                    )}
                </div>

                <Dialog
                    id="contact-us-dialog"
                    title={t("ContactUs")}
                    ref={this.dialogRef}
                    closable
                >
                    <div className="emails">
                        {CONTACT_US_EMAILS.map((email) => (
                            <Link href={`mailto:${email}`} key={email}>
                                {email}
                            </Link>
                        ))}
                    </div>
                </Dialog>
            </div>
        )
    }
}

interface IProps {
    className?: string,
    description?: string,
    useButtonAsAText?: boolean,
    t?:any
}

interface IState {
    isDialogOpened: boolean
}

export default withTranslation()(ContactUsDialog);