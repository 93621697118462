import React from 'react';
import { Table as MuiTable, TableProps, TableHead, TableBody, TableCell, TableContainer, TableRow, Paper, TableBodyProps, TableContainerProps } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
class Table extends React.Component<React.PropsWithChildren<IProps>, IState> {

    render() {
        const { titles, items, children, tableBodyProps, tableContainerProps, t, ...props } = this.props;

        return (
            <TableContainer {...(tableContainerProps || {})} component={Paper} className="pantalone-table">
                <MuiTable {...props}>
                    <TableHead>
                        <TableRow>
                            {titles.map((title, idx) => <TableCell key={idx}>{t(title)}</TableCell>)}
                        </TableRow>
                    </TableHead>

                    <TableBody {...(tableBodyProps || {})}>
                        {items.map((item) => (
                            <TableRow key={item.id || Math.random()}>
                                {Object.values(item).map((details: any, idx) => (
                                    <TableCell key={idx}>{details}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </MuiTable>

                {children}
            </TableContainer>
        )
    }
}

interface IProps extends TableProps {
    titles: string[],
    items: any[],
    tableBodyProps?: TableBodyProps
    tableContainerProps?: TableContainerProps,
    t?:any
}

interface IState {

}

export default withTranslation()(Table);