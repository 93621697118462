import React from 'react';
import { CssBaseline, ThemeProvider, createTheme, Theme, Grid, Typography } from '@material-ui/core';
import Header from './Header.web';
import Sidebar from './Sidebar.web';
import './assets/styles/main.css';
import './assets/styles/components.css';
import { classNames } from './utilities/utils';
import LanguageHelper from './utilities/LanguageHelper';

class MainLayout extends React.Component<React.PropsWithChildren<IProps>, IState> {
    constructor(props: React.PropsWithChildren<IProps>) {
        super(props);

        const theme = createTheme({
            palette: {
                primary: { main: '#194d97' },
                success: { main: '#41c702' },
                error: { main: '#f41216' },
                grey: {
                    "500": '#7f7f7f'
                }
            },
            typography: {
                fontFamily: 'DM Sans, -apple-system, Roboto, "Helvetica Neue", sans-serif',
                button: {
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: 'unset'
                },
                body1: { fontSize: 16 },
                body2: { fontSize: 16 },
                h1: { fontSize: 22, fontWeight: 700 },
                h2: { fontSize: 20, fontWeight: 700 },
                h3: { fontSize: 18, fontWeight: 500 },
                h4: { fontSize: 16, fontWeight: 700 },
                h5: { fontSize: 14, fontWeight: 700 },
                h6: { fontSize: 12, fontWeight: 700 },
                subtitle1: { fontWeight: 600, color: 'rgb(158, 158, 158)' },
                subtitle2: { fontWeight: 500, color: 'rgb(158, 158, 158)' }
            },
            overrides: {
                MuiButton: {
                    text: {
                        textTransform: 'unset',
                        fontWeight: 600
                    },
                    root: {
                        borderRadius: 25
                    }
                }
            }
        });

        this.state = { theme: theme };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            this.setWindowHeight();
            window.addEventListener('resize', this.setWindowHeight);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setWindowHeight);
    }

    setWindowHeight() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.outerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    render() {
        const { theme } = this.state;
        const { useHeader = true, useSidebar = true, id = '', title = '', productSearchText } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />

                {useHeader === true && (
                    <Header
                        navigation={this.props.navigation}
                        productSearchText={productSearchText}
                        cartCount={this.props.cartCount}
                    />
                )}

                {useSidebar ? (
                    <Grid
                        container
                        className={classNames({
                            'main-layout-wrapper': true,
                            '__use-header': useHeader,
                        })}
                    >
                        <Sidebar navigation={this.props.navigation} />

                        <Grid item className="content" component="main" id={id}>
                            {title && (
                                <Typography variant="h1" component="h1" className="page-title">{LanguageHelper.getLangText(title)}</Typography>
                            )}

                            {this.props.children}

                            <div className="padding-box" />
                        </Grid>
                    </Grid>
                ) : (
                    <main
                        id={id}
                        className={classNames({
                            'main-layout-without-sidebar': true,
                            '__use-header': useHeader,
                        })}
                    >
                        {title && (
                            <Typography variant="h1" component="h1" className="page-title">{LanguageHelper.getLangText(title)}</Typography>
                        )}

                        {this.props.children}
                    </main>
                )}
            </ThemeProvider>
        )
    }
}

interface IProps {
    id?: string,
    title?: string,
    useHeader?: boolean,
    useSidebar?: boolean,
    navigation?: any,
    productSearchText?: string,
    cartCount?: number
}

interface IState {
    theme: Theme
}

export default MainLayout;