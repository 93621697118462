import React from 'react';
// Customizable Area Start
import { Button, Typography, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Input from '../../../components/src/Input.web';
import Dialog from '../../../components/src/Dialog.web';
import {IProduct } from '../../../components/src/types/types';
import { configJSON } from './CatalogueController';
import { withTranslation } from 'react-i18next';

// Customizable Area End
export class RequestProduct extends React.Component<IProps, IState> {
    // Customizable Area Start
    private dialogRef = React.createRef<Dialog>();

    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.submitRequestProductForm = this.submitRequestProductForm.bind(this);

        this.state = {
            productName: '',
            productQuantities: {} as IState['productQuantities'],
            clickedProductId: -1
        }
    }

    
    closeDialog(isSelfClose = false) {
        this.setState({
            productName: '',
            errorMessage: '',
        }, () => {
            this.props.onDialogClose();

            if (isSelfClose) {
                this.dialogRef.current?.closeDialog();
            }
        });
    }

    openDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
            this.dialogRef.current.openDialog();
        }
    }

    onInputBlur(event: React.ChangeEvent<HTMLInputElement>) {
        const val = event.target.value;

        if (val) {
            return this.setState({ productName: val });
        }

        this.setState({
            errorMessage: configJSON.requestProductDialogErrorMessage
        });
    }

    submitRequestProductForm() {
        if (this.state.errorMessage) { return; }
        if (!this.state.productName) {
            return this.setState({ errorMessage: configJSON.requestProductDialogErrorMessage })
        }

        this.props.onFormSubmit(this.state.productName);
    }

    onInputFocus() {
        if (this.state.errorMessage) {
            this.setState({ errorMessage: '' });
        }
    }
      // Customizable Area End
    render() {
        // Customizable Area Start
        const { t} = this.props;

        return (
            <div className="products-table">        
                <div className="no-products-found">
                    <span />
                    <Button data-test-id='requestproductbtn' color="primary" onClick={this.openDialog} variant="contained">{t(configJSON.requestProductDialogRequestProductText)}</Button>
                </div>

                <Dialog
                    id="request-new-product"
                    ref={this.dialogRef}
                    title={this.props.isRequestProductSubmitted ? t('Success') : t(configJSON.requestProductDialogRequestProductText)}
                    onClose={this.closeDialog}
                    closable
                >
                    {this.props.isRequestProductSubmitted ? (
                        <div className="submitted-view">
                            <CheckCircleIcon className="check-icon" />

                            <Typography className="description" variant="body2">
                                {t(configJSON.requestProductDialogSuccessText)}
                            </Typography>

                            <Button
                                variant="contained"
                                className="rounded-button"
                                onClick={() => this.closeDialog(true)}
                                color="primary"
                                fullWidth
                                data-test-id="closeDailogbtn"
                            >
                                {t(configJSON.requestProductDialogSuccessButton)}
                            </Button>
                        </div>
                    ) : (
                        <div className="form-view">
                            {this.props.isRequestProductLoading === true ? (
                                <div className="loading-section">
                                    <CircularProgress
                                        style={{ width: 64, height: 64 }}
                                        color="primary"
                                    />
                                </div>
                            ) : (
                                <>
                                    <Typography className="description" variant="body2">
                                        {t(configJSON.requestProductDialogText)}
                                    </Typography>

                                    <Input
                                        label={t(configJSON.requestProductDialogEmailLabel)}
                                        type="text"
                                        onBlur={this.onInputBlur}
                                        onFocus={this.onInputFocus}
                                        fullWidth
                                        data-test-id="inputtest"
                                        errorMessage={<span>{t(this.state.errorMessage)}</span>}
                                    />

                                    <Button
                                        className="rounded-button"
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        onClick={this.submitRequestProductForm}
                                        data-test-id="submitRequestbtn"
                                    >
                                        {t(configJSON.requestProductDialogButtonText)}
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </Dialog>
            </div>
        )
        // Customizable Area End
    }
}
// Customizable Area Start
interface IProps {
    isRequestProductSubmitted: boolean,
    isRequestProductLoading: boolean,
    onFormSubmit: (email: string) => void,
    onDialogClose: () => void,
    isNewProductsLoading: boolean,
    t?: any
}
interface IState {
    clickedProductId: IProduct['id'] | string
    productName: string,
    errorMessage?: string,
    productQuantities: Record<string, number | string>
}

export default withTranslation()(RequestProduct);
// Customizable Area End