import React from 'react';
import { Typography, withTheme, Theme } from '@material-ui/core';
import { classNames } from './utilities/utils';

class Input extends React.Component<(IProps & React.HTMLProps<HTMLInputElement>), IState> {
    render() {
        const {
            label,
            id = `id_${(label || Math.random().toString()).replace(/\s*/gmi, '')}`,
            fullWidth = false,
            theme,
            startAdornment,
            endAdornment,
            errorMessage,
            extra,
            ...inputProps
        } = this.props;

        return (
            <div className={classNames({
                'text-field': true,
                '__full-width': fullWidth,
                '__start-adornment': !!startAdornment,
                '__end-adornment': !!endAdornment,
            })}>
                {label && (
                    <Typography
                        component="label"
                        variant="subtitle2"
                        htmlFor={id}
                    >
                        {label}
                    </Typography>
                )}

                <div className="input-wrapper">
                    {startAdornment && (
                        <div className="start-adornment">
                            {startAdornment}
                        </div>
                    )}

                    {(inputProps.type === 'textarea') ? (
                        <textarea
                            id={id}
                            style={{
                                ...inputProps.style,
                                borderColor: inputProps.style?.borderColor || theme.palette.primary.dark,
                            }}
                            {...inputProps as any}
                        />
                    ) : (
                        <>
                            <input
                                id={id}
                                style={{
                                    ...inputProps.style,
                                    borderColor: inputProps.style?.borderColor || theme.palette.primary.dark,
                                }}
                                {...inputProps}
                            />

                            {endAdornment && (
                                <div className="end-adornment">
                                    {endAdornment}
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className="extra-section">
                    <div className="error-message">
                        {(errorMessage && typeof errorMessage !== 'string' && typeof errorMessage.props?.render !== 'function') ? (
                            <Typography component="small" color="error" className="error-message">
                                {errorMessage}
                            </Typography>
                        ) : errorMessage}
                    </div>

                    {extra && extra()}
                </div>
            </div>
        )
    }
}

export interface IProps {
    id?: string,
    label?: string
    fullWidth?: boolean,
    startAdornment?: React.ReactNode
    endAdornment?: React.ReactNode,
    errorMessage?: JSX.Element | string,
    extra?: Function,
    theme: Theme,
}
interface IState {}

export default withTheme(Input);