import React from 'react';
import { IconButton } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import Input, { IProps as IInputProps } from './Input.web';

class PasswordField extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.toggleShowPassword = this.toggleShowPassword.bind(this);
        this.state = { showPassword: false }
    }

    toggleShowPassword() {
        this.setState((prevState) => ({
            ...prevState,
            showPassword: !prevState.showPassword
        }));
    }

    render() {
        const { showPassword } = this.state;

        return (
            <Input
                type={showPassword ? 'text' : 'password'}
                endAdornment={(
                    <IconButton onClick={this.toggleShowPassword} disabled={!!this.props.readOnly}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                )}
                {...this.props}
            />
        )
    }
}

interface IProps extends Omit<IInputProps, 'theme'> {
    name?:string,
    value?: string,
    defaultValue?:string,
    readOnly?: boolean,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    required?: boolean,
    error?:any
}
interface IState {
    showPassword: boolean
}

export default PasswordField;