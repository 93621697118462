import React from 'react';
// Customizable Area Start
import {
    IconButton,
    Typography,
    Paper,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import Table from "../../../components/src/Table.web";
import { formatPrice } from "../../../components/src/utilities/utils";
import { Order } from './types';
import { withTranslation } from 'react-i18next';
import { configJSON } from './OrderDetails';
import MergeEngineUtilities from '../../utilities/src/MergeEngineUtilities';
import CustomTooltip from "../../../components/src/Tooltip.web";
// Customizable Area End
// Customizable Area Start
const ActiveOrderDetails: React.FC<IProps> = ({
    loading,
    activeOrder,
    t
}) => {
    const tableTitles = React.useMemo(() => {
        const ActiveOrdersTableName = ["ProductName","ProductDescription","Quantity","Price/MT","NetAmount"];
        if (!Array.isArray(activeOrder.order_items) || (Array.isArray(activeOrder.order_items) && activeOrder.order_items.length === 0)) { return ActiveOrdersTableName; }
        
        const baseUnit = activeOrder.order_items[0].base_unit;
        const staticUnit = activeOrder.order_items[0].static_unit;
        const isAllUnitsSame = activeOrder.order_items.every((product) => product.base_unit === baseUnit);
        const priceText = isAllUnitsSame
                            ? `${t("price")}/${baseUnit ? baseUnit : staticUnit}`
                            : t("Price/Unit");
        ActiveOrdersTableName[3] = priceText;
        return ActiveOrdersTableName.map((title) => (title));
    }, [activeOrder.order_items]);

    // Customizable Area Start
    const onButtonClick = () => {
        fetch(`${configJSON.apiEndpoint.baseURL + configJSON.downloadDocs}?id=${activeOrder.id}`,{
            method: configJSON.getMethod,
            headers: {
              'Content-Type': configJSON.apiContentType, 
              token: MergeEngineUtilities._token 
            },
          })
        .then(response => response.blob())
        .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'erp.pdf';
        link.click();

        URL.revokeObjectURL(url);  
      })
      .catch(error => {
        console.error('Error downloading PDF:', error);
      });
    }
    // Customizable Area End
   // Customizable Area End
    return (
        // Customizable Area Start
        <div className="active-order-section">
            {loading ? (
                <div className="loading-section" data-testid="active-order-loading">
                    <CircularProgress
                        color="primary"
                        style={{ width: 64, height: 64 }}
                    />
                </div>
            ) : (
                <>
                    {activeOrder.id ? (
                        <>
                            <Grid container className="top-section" spacing={3}>
                                <Grid
                                    item
                                    className="order-details"
                                    component="div"
                                    md={7}
                                >
                                    <Typography variant="h1" component="h1" data-testid="order-id-title">{t('Order_ID')} {activeOrder.id}</Typography>

                                    <Paper elevation={0} className="order-section-card">
                                        <div className="order-detail-item">
                                            <Typography variant="subtitle1" component="strong">{t('Customer_ID')}</Typography>
                                            <span data-testid="customer-id">{activeOrder.customer.id}</span>
                                        </div>

                                        <div className="order-detail-item">
                                            <Typography variant="subtitle1" component="strong">{t('Order_Date')}</Typography>
                                            <span data-testid="order-date">{activeOrder.order_date}</span>
                                        </div>

                                        <div className="order-detail-item">
                                            <Typography variant="subtitle1" component="strong">{t('Total_Amount')}</Typography>
                                            <span data-testid="total-fees">€ {formatPrice(activeOrder.total_amount)}</span>
                                        </div>

                                        <div className="order-detail-item">
                                            <Typography variant="subtitle1" component="strong">{t('Status')}</Typography>
                                            <span data-testid="status">{activeOrder.status}</span>
                                        </div>

                                        <div className="order-detail-item">
                                            <Typography variant="subtitle1" component="strong">{t('Delivery_Date')}</Typography>
                                            <span data-testid="delivery-date">????</span>
                                        </div>
                                    </Paper>
                                </Grid>

                                <Grid
                                    item
                                    className="shipping-address"
                                    component="div"
                                    md={5}
                                >
                                    <Typography variant="h1" component="h1">{t('ShippingAddress')}:</Typography>

                                    <Paper elevation={0} className="order-section-card">
                                        {activeOrder.alternate_address ? activeOrder.alternate_address : (
                                            <>
                                                <div className="section-item">
                                                    {t('Street')}: <span data-testid="address-street">{activeOrder.address.address_streeta}</span>
                                                </div>

                                                <div className="section-item">
                                                    {t('City')}: <span>{activeOrder.address.address_citya}</span>
                                                </div>

                                                <div className="section-item">
                                                    {t('State/Province/Area')}: <span>{activeOrder.address.address_statea}</span>
                                                </div>

                                                <div className="section-item">
                                                    {t('PhoneNumber')}: <span>{activeOrder.address.address_numbera}</span>
                                                </div>

                                                <div className="section-item">
                                                    {t('ZipCode')}: <span>{activeOrder.address.address_postcodea}</span>
                                                </div>
                                            </>
                                        )}
                                    </Paper>
                                </Grid>
                            </Grid>

                            <div className="table-title">
                                <Typography variant="h4" component="h4">{t('ORDER_ITEMS')}</Typography>
                                <CustomTooltip title={t('Download')}>
                                <IconButton data-testid="onDownloadPdf" onClick={onButtonClick}><GetAppIcon color="primary" /></IconButton>
                                </CustomTooltip>
                            </div>

                            <Table
                                stickyHeader
                                className="orders-products-table"
                                titles={tableTitles}
                                items={activeOrder.order_items.map((item) => {
                                    return {
                                    name: item?.name? item?.name: item.static_product,
                                    description: item.description?item.description:item.static_product_description,
                                    quantity: `${item.quantity} ${item?.base_unit?item.base_unit:item.static_unit}.`,
                                    price: (
                                        <div className="price">                                            
                                            <span className="span">€</span><span>{item.price}</span>
                                        </div>
                                    ),
                                    totalAmount: (
                                        <div className="price">
                                        <span className="span">€</span><span>{item.net_amount}</span>
                                        </div>
                                    )
                                }})}
                            />
                        </>
                    ) : (
                        <span data-testid="no-order-found-text">{t('NoOrdersFound')}</span>
                    )}
                </>
            )}
        </div>
         // Customizable Area End
    )
}
// Customizable Area Start
interface IProps {
    loading: boolean,
    activeOrder: Order,
    t?:any
}

export default withTranslation()(ActiveOrderDetails);
 // Customizable Area End