Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfbestsellerfeature";
exports.labelBodyText = "cfbestsellerfeature Body";

exports.btnExampleTitle = "CLICK ME";
exports.fetchBestSellersApiEndpoint = 'bx_block_shopping_cart/best_seller_products';
exports.getApiMethod = 'GET';

exports.title = 'BestsellerItems';
exports.noItemsFound = 'NoItemsFound'
exports.resultText = 'Result';

exports.bestSellerItems = [
  {
    "category": {
        "id": 13,
        "name": "ACCESSORIES",
        "created_at": "2023-03-23T06:21:59.173Z",
        "updated_at": "2023-03-23T06:21:59.173Z",
        "admin_user_id": null,
        "rank": null,
        "light_icon": {
            "url": null
        },
        "light_icon_active": {
            "url": null
        },
        "light_icon_inactive": {
            "url": null
        },
        "dark_icon": {
            "url": null
        },
        "dark_icon_active": {
            "url": null
        },
        "dark_icon_inactive": {
            "url": null
        },
        "identifier": null,
        "category_code": null
    },
    "sub_category": {
        "id": 47,
        "name": "FIXING ARTICLES1",
        "created_at": "2023-03-23T11:55:19.731Z",
        "updated_at": "2023-03-23T12:11:16.440Z",
        "parent_id": null,
        "rank": null,
        "sub_category_code": null
    },
    "id": "4",
    "brand": null,
    "name": "TESTNAME",
    "sku": "",
    "description": "TEST",
    "manufacture_date": null,
    "length": null,
    "breadth": null,
    "height": null,
    "stock_qty": null,
    "availability": null,
    "weight": null,
    "price": null,
    "recommended": false,
    "on_sale": false,
    "sale_price": null,
    "discount": null,
    "product_id": "5152",
    "product_type": "",
    "group_code": "",
    "base_unit": "MT",
    "next_delivery_date": null,
    "quantity_arriving": null,
    "lead_days": null,
    "schedule": null,
    "diameter": null,
    "reseller_price": null,
    "engineering_price": null,
    "material": "",
    "timestamp": null,
    "alternate_description": "",
    "images": null,
    "average_rating": 0
  }
];
// Customizable Area End