import { setStorageData, getStorageData, removeStorageData } from '../../../framework/src/Utilities';
import { ICart } from '../types/types';
import { safeJSONparse } from './utils';

export const getToken = async(): Promise<string> => {
    const token = await getStorageData('authToken');
    return token || '';
}

export const setToken = async(token: string) => {
    await setStorageData('authToken', token);
}

export const getCustomerId = async() => {
    const customerId = await getStorageData('customer_id');
    return customerId;
}

export const getProfileId = async() => {
    const profileId = await getStorageData('customer_code');
    return profileId;
}

export const getCustomerType = async() => {
    const customerType = await getStorageData('customer_type');
    return customerType;
}

export const setCustomerId = async(customer_id: number) => {
    await setStorageData('customer_id', customer_id)
}

export const setProfileId = async(profile_id: number) => {
    await setStorageData('customer_code', profile_id)
}

export const setCustomerType = async(customer_type: 'R' | 'E') => {
    await setStorageData('customer_type', customer_type)
}

export const logout = async() => {
    await removeStorageData('authToken')
    await removeStorageData('profile_id')
    await removeStorageData('customer_id')
    await removeStorageData('customer_type');
}

export const saveCart = async(cart: ICart) => {
    const c = { ...cart }
    const cid = await getCustomerId();
    c.productDetails = {};
    c.prices = {};
    c.totalPrice = 0;

    await setStorageData(`cart_${cid}`, JSON.stringify(c));
}
export const saveCartConfirm = async(cart: ICart) => {
    const c = { ...cart }
    c.productDetails = {};
    c.prices = {};
    c.totalPrice = 0;

    await setStorageData(`cartData`, JSON.stringify(c));
}

export const getCart = async(): Promise<ICart> => {
    const cid = await getCustomerId();
    const cart = await getStorageData(`cart_${cid}`);
    return safeJSONparse(cart, { products: {}, totalPrice: 0, prices: {}, productDetails: {} });
}
