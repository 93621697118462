import React from "react";

//Customizable Area Start
import { Button, Typography } from "@material-ui/core";
import { ErrorMessage, Formik} from "formik";
import * as Yup from "yup";
import ResetPasswordController, {
  Props,
  configJSON,
} from "./ResetPasswordController";
import LanguageHelper from "../../../components/src/utilities/LanguageHelper";
import PasswordField from "../../../components/src/PasswordField.web";
import MainLayout from "../../../components/src/MainLayout.web";
import "./resetpassword.css";
//Customizable Area End

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  validationSchemaAdminobj = Yup.object().shape({
    new_password: Yup.string()
      .required(configJSON.passwordErrorMessage)
      .matches(configJSON.passwordRegex, configJSON.passwordValiodationText),
    confirm_password: Yup.string()
      .required(configJSON.confirmPasswordErrorMessage)
      .oneOf(
        [Yup.ref("new_password"), null],
        configJSON.notMatchPasswordErrorMessage
      ),
  });

  render() {
    return (
      <MainLayout useHeader={false} useSidebar={false} id="reset-password-page">
        <div className="reset-container">
          <div className="right-side">
            <div className="right-side-header">
              <Typography component="h1" variant="h1" color="primary">
                {LanguageHelper.getLangText(configJSON.pageTitle)}
              </Typography>
            </div>

            <div className="right-side-content">
              <Formik
                validationSchema={this.validationSchemaAdminobj}
                initialValues={this.state}
                onSubmit={(values) => {
                  this.ResetPassword(values);
                }}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                    className="reset-form"                
                  >
                    <PasswordField
                      name="new_password"
                      label={LanguageHelper.getLangText(configJSON.newPassword)}
                      fullWidth
                      onChange={handleChange}
                      value={values.new_password}
                      errorMessage={<ErrorMessage name="new_password" />}
                    />

                    <PasswordField
                      name="confirm_password"
                      label={LanguageHelper.getLangText(
                        configJSON.confirmPassword
                      )}
                      fullWidth
                      onChange={handleChange}
                      value={values.confirm_password}
                      errorMessage={<ErrorMessage name="confirm_password" />}
                    />

                    <div className="content-footer">
                      <Button
                        className="submit-button"
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        {configJSON.resetButtonText}
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

// Customizable Area Start
//Customizable Area End
