import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ICart, IProduct } from "../../../components/src/types/types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t:any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loading: 'INIT' | 'NEW_PRODUCTS' | null;
  cart: ICart;
  productsLoader: Record<string, boolean>;
  bestSellers: IProduct[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfbestsellerfeatureController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationApiCallId: string = '';
  fetchLanguageTextsCallId: string = ''
  fetchBestSellersCallId: string = '';
  quantityUpdateCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start,
      cart: { products: {}, prices: {}, productDetails: {}, totalPrice: 0 },
      loading: 'INIT',
      bestSellers: [],
      productsLoader: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleValidationResponse = this.handleValidationResponse.bind(this);
    this.handleBestSellersResponse = this.handleBestSellersResponse.bind(this);
    this.initCart = this.initCart.bind(this);
    this.updateProductQuantityFromCart = this.updateProductQuantityFromCart.bind(this);
    this.fetchBestSellers = this.fetchBestSellers.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      this.handleValidationResponse(responseJson, apiRequestCallId);
      this.handleBestSellersResponse(responseJson, apiRequestCallId);

      if (this.fetchLanguageTextsCallId === apiRequestCallId) {
        MergeEngineUtilities.handleLanguageResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    if (MergeEngineUtilities.isLoggedIn()) {
      this.fetchBestSellers();
    } else {
      this.validationApiCallId = await MergeEngineUtilities.validateToken(this.props);
    }

    this.initCart();

    this.fetchLanguageTextsCallId = MergeEngineUtilities.fetchLanguageTexts();
  }

  handleValidationResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.validationApiCallId) { return; }

    if (responseJson && Array.isArray(responseJson.messages) && responseJson.messages[0] && responseJson.messages[0].Token) {
      this.fetchBestSellers();
    } else {
      MergeEngineUtilities.logout(this.props);
    }
  }

  handleBestSellersResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.fetchBestSellersCallId) { return; }

    const state: Partial<S> = { loading: null }

    if (responseJson && Array.isArray(responseJson.data)) {
      state.bestSellers = [
        ...this.state.bestSellers,
        ...responseJson.data.map((item: any) => {
          return {
            ...item.attributes,
            ...item.attributes.catalogue,
            updated_at: item.attributes.updated_at,
            price: item.attributes.price
          }
        })
      ];
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }

    this.setState((prev) => ({ ...prev, ...state }));
  }

  fetchBestSellers(pageNumber: number = 1) {
    if (pageNumber % 1 !== 0) {
      return;
    }

    this.setState({
      loading: pageNumber === 1 ? 'INIT' : 'NEW_PRODUCTS'
    }, () => {
      const requestMessage = MergeEngineUtilities.createRequestMessage({
        endpoint: `${configJSON.fetchBestSellersApiEndpoint}?page=${pageNumber}`,
        method: configJSON.getApiMethod,
        header: {},
        useToken: true,
      })
  
      this.fetchBestSellersCallId = requestMessage.messageId;
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

  async initCart() {
    const cart = await MergeEngineUtilities.initCart();
    this.setState({ cart: cart });
  }

  async updateProductQuantityFromCart(productId: IProduct['product_id'], qty: number, operation: 'DELETE' | 'UPDATE') {
    const cart = await MergeEngineUtilities.updateCart(productId, qty, operation);
    this.setState({ cart: cart })
  }
  // Customizable Area End
}
