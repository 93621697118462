import React from "react";

import {
  IconButton,
  // Customizable Area Start
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { FindInPageOutlined } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MainLayout from "../../../components/src/MainLayout.web";
import Table from "../../../components/src/Table.web";
import "./ShipingDocument.css";
// Customizable Area End

import DocumentstorageController, {
  configJSON,
} from "./DocumentstorageController";
import { withTranslation } from "react-i18next";
import {
  ShipmentDocumentTableName,
  ShipmentDocumentSecondTableName,
} from "../../../components/src/tableNameConstants";
import CustomTooltip from "../../../components/src/Tooltip.web";

export class ShipmentDocument extends DocumentstorageController {
  // Customizable Area Start
  renderShipmentDocumentTable = (t: any) => {
    const { shipmentDocumentData } = configJSON;
    if (
      shipmentDocumentData !== null &&
      Array.isArray(shipmentDocumentData) &&
      shipmentDocumentData.length > 0
    ) {
      return (
        <Table
          stickyHeader
          className="shipment-document-table"
          titles={ShipmentDocumentTableName.map((table: any) => t(table.title))}
          items={shipmentDocumentData.map((item: any) => {
            const orderDate = new Date(item.delivery_date);
            const days = orderDate.getDate();
            const months = orderDate.getMonth() + 1;
            const year = orderDate.getFullYear().toString().slice(-2);
            const formattedDate = `${days}/${
              (months < 10 ? "0" : "") + months
            }/${year}`;
            return {
              order_id: item.order_id,
              DDT_Id: item.DDT_id,
              Delivery_Date: formattedDate,
              shipping_address: (
                <div className="shipping-address-item">
                  <b>{item.customer_fullname}</b>
                  <span>{item.shipping_address}</span>
                </div>
              ),
              invoice_id: item.invoice_id,
              order_date: new Date(item.order_date).toLocaleDateString("en-GB"),
              actions: (
                <div style={{ display: "flex" }}>
                  <CustomTooltip
                    title={t("Documents")}
                    style={{ marginTop: "-5px", marginBottom: "-5px" }}
                  >
                    <IconButton color="primary">
                      <FindInPageOutlined
                        color="primary"
                      />
                    </IconButton>
                  </CustomTooltip>
                </div>
              ),
            };
          })}
        />
      );
    }else if (this.state.shipmentError){
      return t("NoOrdersFound")
    }
    else {
      return (
        <div className="loading-section" id="shipmentData-loading">
          <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
        </div>
      );
    }
  };
  renderShipmentDocumentSecondTable = (t: any) => {
    const { shipmentDocumentData } = configJSON;
    if (
      shipmentDocumentData !== null &&
      Array.isArray(shipmentDocumentData) &&
      shipmentDocumentData.length > 0
    ) {
      return (
        <Table
          style={{ marginTop: "90px" }}
          stickyHeader
          titles={ShipmentDocumentSecondTableName.map((table: any) =>
            t(table.title)
          )}
          className="shipment-document-second-table"
          items={shipmentDocumentData.map((item: any) => {
            const orderDate = new Date(item.delivery_date);
            const day = orderDate.getDate();
            const month = orderDate.getMonth() + 1;
            const year = orderDate.getFullYear().toString().slice(-2);
            const formattedDate = `${day}/${
              (month < 10 ? "0" : "") + month
            }/${year}`;
            return {
              order_id: item.order_id,
              shipping_address: (
                <div className="shipping-address-item">
                  <b>{item.customer_fullname}</b>
                  <span>{item.shipping_address}</span>
                </div>
              ),
              Delivery_Date: formattedDate,
              order_date: new Date(item.order_date).toLocaleDateString("en-GB"),
              actions: (
                <div style={{ display: "flex"}}>
                  <CustomTooltip
                    title={t("View")}
                    style={{ marginTop: "-5px", marginBottom: "-5px" }}
                  >
                    <IconButton color="primary">
                      <VisibilityIcon
                        color="primary"
                      />
                    </IconButton>
                  </CustomTooltip>
                  <CustomTooltip
                    title={t("Documents")}
                    style={{ marginTop: "-5px", marginBottom: "-5px" , marginLeft:"45px"}}
                  >
                    <IconButton color="primary">
                      <FindInPageOutlined
                        color="primary"
                      />
                    </IconButton>
                  </CustomTooltip>
                </div>
              ),
            };
          })}
        />
      );
    } else if (this.state.shipmentError){
      return t("NoOrdersFound")
    }
    else {
      return (
        <div className="loading-section" data-testid="orders-loading">
          <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
        </div>
      );
    }
  };
  // Customizable Area End

  render() {
    const { t } = this.props;
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <MainLayout
        id="shipment-document"
        navigation={this.props.navigation}
        title={t("Shipment")}
      >
        {this.renderShipmentDocumentTable(t)}
        {this.renderShipmentDocumentSecondTable(t)}
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(ShipmentDocument);
// Customizable Area End
