Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"

exports.fetchNotificationsApiCallId = 'bx_block_notifications/notifications';
exports.readAllNotificationsApiCallId = 'bx_block_notifications/notifications/mark_as_read'
exports.getApiMethod = 'GET';
exports.pageTitle = 'Notifications';
exports.putApiMethod = 'PUT';
// Customizable Area End