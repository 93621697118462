import React from 'react';
// Customizable Area Start
import { Button, Typography, CircularProgress, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RequestNewAddress from '../../../components/src/RequestNewAddress.web';
import Dialog from '../../../components/src/Dialog.web';
import Input from '../../../components/src/Input.web';
import { IAddress } from '../../../components/src/types/types';
import { mergeAddress } from '../../../components/src/utilities/utils';
import { withTranslation } from "react-i18next";

const configJSON = require("./config");
// Customizable Area End
export class SubmitOrderDialog extends React.Component<IProps, IState> {
    // Customizable Area Start
    private dialogRef = React.createRef<Dialog>();

    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onClickSubmitOrder = this.onClickSubmitOrder.bind(this);
        this.selectAddress = this.selectAddress.bind(this);

        this.onAlternateAddressInput = this.onAlternateAddressInput.bind(this);
        this.requestNewAddress = this.requestNewAddress.bind(this);

        this.state = { selectedAddressId: '', alternateAddress: '' }
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>): void {
        if (nextProps.isSubmitted === true) {
            this.openDialog()
        }
    }

    openDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
            this.dialogRef.current.openDialog();
        }
    }

    closeDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.closeDialog === 'function') {
            this.dialogRef.current.closeDialog();
        }
    }

    onClickSubmitOrder() {
        if (this.props.page === 'CHECKOUT') {
            return this.openDialog();
        }

        this.props.submitOrder(this.state.selectedAddressId!, this.state.alternateAddress);
    }

    selectAddress(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ selectedAddressId: event.target.value })
    }

    onAlternateAddressInput(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ alternateAddress: event.target.value })
    }

    requestNewAddress() {
        this.props.createNewAddress(this.state.alternateAddress)
    }

    render() {
        const { isLoading, isSubmitted, isRequestAddressSubmitted, hasCartProducts, createdOrderDetails,t } = this.props;
        let LIST_ITEMS: any = [];

        if (createdOrderDetails) {
            LIST_ITEMS = [
                { title: 'Customer_ID', value: createdOrderDetails.customer_id },
                { title: 'Order_Date', value: createdOrderDetails.created_at },
                { title: 'Total_Amount', value: `€ ${createdOrderDetails.total_amount}` },
                { title: 'Status', value: createdOrderDetails.status },
            ]
        }
       // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Button
                    variant="contained"
                    disabled={isLoading || !hasCartProducts}
                    color="primary"
                    onClick={this.onClickSubmitOrder}
                >
                    {isLoading ? (
                        <CircularProgress color="primary" size={24} />
                    ) : (
                        <span>
                            {t(this.props.page === 'CART' ?
                                configJSON.submitOrderButtonText
                                : configJSON.placeOrderButtonText
                            )}
                        </span>
                    )}
                </Button>

                <Dialog
                    id="submit-order-dialog"
                    ref={this.dialogRef}
                    onClose={this.props.onDialogClose}
                    closable={!isSubmitted}
                    disablePortal
                >
                    {(isSubmitted && this.props.createdOrderDetails) ? (
                        <div className="success-view">
                            <CheckCircleIcon className="check-icon" />

                            <Typography component="strong" className="action">{t('OrderSuccess')}</Typography>

                            <Typography component="strong" className="order-id">{t('Order_ID')} {this.props.createdOrderDetails.id}</Typography>

                            <div className="list">
                                {LIST_ITEMS.map((item: any) => (
                                    <div className="list-item" key={item.id}>
                                        <Typography component="strong">{t(item.title)}</Typography>
                                        <Typography component="span">{item.value}</Typography>
                                    </div>
                                ))}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.props.navigateToOrderDetails}
                                    className="view-order-button"
                                >
                                    {t(configJSON.viewOrderText)}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {isRequestAddressSubmitted && (
                                <div className="new-address-success-section">
                                    <CheckCircleIcon className="check-icon" />

                                    <Typography variant="body2" className="description">
                                        {t('RequestPlaced')}
                                    </Typography>

                                    <Button
                                        id="rounded-button1"
                                        className="rounded-button"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.closeDialog}
                                        fullWidth
                                    >
                                       OK
                                    </Button>
                                </div>
                            )}

                            {isRequestAddressSubmitted !== true && (
                                <div className="to-be-shipped-view">
                                    <Typography component="strong" className="view-title" color="primary">
                                        {t(configJSON.toBeShippedText)}
                                    </Typography>

                                    <div className="options">
                                        <RadioGroup id="shipping-addresses" className="shipping-addresses" value={this.state.selectedAddressId} onChange={this.selectAddress}>
                                            <Typography color="primary" className="select-address-label">{t('AddressDeliveryText')}</Typography>

                                            {Array.isArray(this.props.addresses) ? this.props.addresses.filter((item) => !!item.address_citya).map((item) => (
                                                <FormControlLabel
                                                    key={item.id}
                                                    value={item.id}
                                                    control={<Radio color="primary" checked={this.state.selectedAddressId === item.id.toString()} />}
                                                    className="form-control-label"
                                                    label={(<Input type="text" label={item.address_namea} value={mergeAddress(item)} fullWidth readOnly />)}
                                                />
                                            )) : (
                                                <span>{t('NoShippingAddressesFound')}!</span>
                                            )}

                                            <FormControlLabel
                                                value="alternate-address"
                                                control={<Radio color="primary" checked={this.state.selectedAddressId === 'alternate-address'} />}
                                                className="form-control-label"
                                                label={<Input type="textarea" id="form-control-label" onChange={this.onAlternateAddressInput} label={t('AlternateAddress')} fullWidth />}
                                            />
                                        </RadioGroup>
                                    </div>

                                    <div className="button-group">
                                        <Button
                                            id="button-group"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.props.submitOrder(this.state.selectedAddressId!, this.state.alternateAddress)}
                                            disabled={(
                                                !this.state.selectedAddressId ||
                                                (this.state.selectedAddressId === 'alternate-address' && this.state.alternateAddress.length === 0)
                                            )}
                                        >
                                            {isLoading ? <CircularProgress color="inherit" size={24} /> : 'OK'}
                                        </Button>

                                        <RequestNewAddress
                                            loading={this.props.createNewAddressLoading}
                                            isSubmitted={isRequestAddressSubmitted}
                                            formSubmit={this.props.createNewAddress}
                                            closeDialog={this.props.onDialogClose}
                                            buttonProps={{
                                                variant: 'outlined',
                                                color: 'primary',
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </Dialog>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export interface ICreatedOrderDetails {
    id: string,
    customer_id: string,
    created_at: string,
    total_amount: string,
    status: string
}

interface IProps {
    isLoading: boolean,
    isSubmitted: boolean,
    submitOrder: (addressId: string, alternateAddress?: string) => void,
    onDialogClose: () => void,
    orderMethod: 'PARTIAL_DELIVERY' | 'FINAL_DELIVERY_ONLY',
    hasStockIssue?: boolean,
    hasCartProducts: boolean,
    addresses: (IAddress & { id: number })[],
    createdOrderDetails?: ICreatedOrderDetails,
    navigateToOrderDetails: () => void,
    page: 'CART' | 'CHECKOUT',
    createNewAddress: (address: string) => void,
    isRequestAddressSubmitted: boolean,
    createNewAddressLoading?: boolean,
    t?:any
}

interface IState {
    selectedAddressId?: string,
    alternateAddress: string
}

export default withTranslation()(SubmitOrderDialog);
// Customizable Area End