import React from "react";

import {
  Typography,
  IconButton,
  // Customizable Area Start
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { FileCopyOutlined, EditOutlined, FindInPageOutlined } from "@material-ui/icons";
import FilterBy from "./FilterBy";
import MainLayout from "../../../components/src/MainLayout.web";
import Table from "../../../components/src/Table.web";
import { formatPrice } from "../../../components/src/utilities/utils";
import './Quotemanagement.css';
// Customizable Area End

import QuotemanagementController, {
  Props,
  configJSON,
} from "./QuotemanagementController";
import QuoteDetailsView from "./QuoteDetailsView.web";
import { withTranslation } from 'react-i18next';
import { SavedQuotesTableName ,ConfirmedQuotesTableName } from "../../../components/src/tableNameConstants";
import { DeleteQuoteDialog } from "./DeleteQuoteDialog.web";
import QuoteDetailsUpdateWeb from "./QuoteDetailsUpdate.web";
import CustomTooltip from "../../../components/src/Tooltip.web";
import ConfirmQuoteDetailsViewWeb from "./ConfirmQuoteDetailsView.web";


export class Quotemanagement extends QuotemanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {t}=this.props
  // Customizable Area Start
    const renderQuoteDetailsView = (state:any) => {
      const {
        activeQuote,
        savedQuotesData,
        loading,
        view,
        addresses,
        newAddressLoading,
        newAddressSubmitted,
        isOrderLoading,
        isOrderSubmitted,
        orderMethod
      } = state;

      return (
        <QuoteDetailsView
          page={activeQuote.page}
          id={activeQuote.id}
          activeQuote={savedQuotesData}
          loading={loading}
          submitOrder={this.submitOrder}
          view={view}
          createNewAddress={this.createNewAddress}
          submitOrderDialogClose={this.submitOrderDialogClose}
          navigateToOrderDetails={this.navigateToOrderDetails}
          createdOrderDetails={state.createdOrderDetails}
          addresses={addresses}
          newAddressLoading={newAddressLoading}
          newAddressSubmitted={newAddressSubmitted}
          isOrderLoading={isOrderLoading}
          isOrderSubmitted={isOrderSubmitted}
          quantityUpdate={this.quantityUpdate}
          orderMethod={orderMethod}
          changeOrderMethod={this.changeOrderMethod}
          fetchQuoteDetail={this.fetchQuoteDetail}
          submitOffer={this.submitOffer}
          DeleteQuote={this.deleteQuoteIfProductDeletedBE}
          isOfferLoading={this.state.isOfferLoading}
        />
      );
    };
    const renderQuoteDetailsUpdate = (state:any) => {
      const {
        activeQuote,
        savedQuotesData,
        loading,
        view,
        addresses,
        newAddressLoading,
        newAddressSubmitted,
        isOrderLoading,
        isOrderSubmitted,
        orderMethod
      } = state;
    
      return (
        <QuoteDetailsUpdateWeb
          page={activeQuote.page}
          id={activeQuote.id}
          activeQuote={savedQuotesData}
          loading={loading}
          submitOrder={this.submitOrder}
          view={view}
          createNewAddress={this.createNewAddress}
          submitOrderDialogClose={this.submitOrderDialogClose}
          navigateToOrderDetails={this.navigateToOrderDetails}
          createdOrderDetails={state.createdOrderDetails}
          addresses={addresses}
          newAddressLoading={newAddressLoading}
          newAddressSubmitted={newAddressSubmitted}
          isOrderLoading={isOrderLoading}
          isOrderSubmitted={isOrderSubmitted}
          quantityUpdate={this.quantityUpdate}
          orderMethod={orderMethod}        
          changeOrderMethod={this.changeOrderMethod}
          cart={this.state.cart}
          fetchCartOnEveryUpdate={this.fetchCartOnEveryUpdate}
          deleteQuoteItem={this.deleteQuoteItem}
          quantity={this.state.quantity}
          product_id={this.state.product_id}
          cartData={this.state.cartData}
          submitOffer={this.submitOffer}
          DeleteQuote={this.deleteQuoteIfProductDeletedBE}
          isOfferLoading={this.state.isOfferLoading}
        />
      );
    };
    const renderConfirmQuoteDetailsView = (state:any) => {
      const {
        activeQuote,
        confirmQuotesData,
        loading,
        view,
        addresses,
        newAddressLoading,
        newAddressSubmitted,
        isConfirmOrderLoading,
        isConfirmOrderSubmitted,
        orderMethod
      } = state;

      return (
        <ConfirmQuoteDetailsViewWeb
          page={activeQuote.page}
          id={activeQuote.id}
          activeQuote={confirmQuotesData}
          loading={loading}
          submitOrder={this.placeOrderFromConfirmQuotes}
          view={view}
          createNewAddress={this.createNewAddress}
          submitOrderDialogClose={this.submitOrderDialogClose}
          navigateToOrderDetails={this.navigateToOrderDetails}
          createdOrderDetails={state.createdOrderDetails}
          addresses={addresses}
          newAddressLoading={newAddressLoading}
          newAddressSubmitted={newAddressSubmitted}
          isOrderLoading={isConfirmOrderLoading}
          isOrderSubmitted={isConfirmOrderSubmitted}
          quantityUpdate={this.quantityUpdate}
          orderMethod={orderMethod}
          changeOrderMethod={this.changeOrderMethod}
          fetchQuoteDetail={this.fetchQuoteDetail}
        />
      );
    };
    const renderDefaultView = (state:any, t:any, configJSON:any) => {
      const { loading } = state;
    
      return (
        <>
          {loading ? (
            <div className="loading-section" data-testid="orders-loading">
              <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
            </div>
          ) : (
            <>
              <Typography variant="h1" component="h1" className="page-title">
                  {t('SavedQuotes')}
                </Typography>
              {renderSavedQuotesTable(state, t, configJSON)}
              <div className="title-section">
                <Typography variant="h1" component="h1" className="page-title">
                  {t('ConfirmedQuotes')}
                </Typography>
                <FilterBy filterValue="CONFIRMED" onFilterValueChanged={() => {}} />
              </div>
              {renderConfirmedQuotesTable(state, t, configJSON)}
            </>
          )}
        </>
      );
    };
    const renderSavedQuotesTable = (state:any, t:any, configJSON:any) => {
      const { savedQuotes } = state;
      const filteredData = savedQuotes?.filter((item: { attributes: { order_items: { data: string | any[]; }; }; }) => item.attributes.order_items.data.length !== 0);
      if (filteredData !== null && Array.isArray(filteredData) && filteredData.length > 0 ) {
        return (
          <Table
            stickyHeader
            className="saved-quotes-table"
            titles={SavedQuotesTableName.map((table:any) => t(table.title))}
            items={filteredData.map((item: { id: number; attributes: { address: { data: { attributes: { address_citya: any; address_numbera: any; address_countya: any; address_statea: any; address_postcodea: any; }; } | null; }; alternate_address: any; order_items: { data: string | any[]; }; delivery_type: string; total_sum: string | number; }; }) =>{
              const address =`${item?.attributes?.address?.data?.attributes?.address_citya} ${item.attributes?.address?.data?.attributes?.address_numbera} ${item?.attributes?.address?.data?.attributes?.address_countya} ${item.attributes?.address?.data?.attributes?.address_statea} ${item?.attributes?.address?.data?.attributes?.address_postcodea}`;
              return {
              cart_id: item.id,
              delivery_address:
               <div className="shipping-address-item">
                <span>
                {item.attributes.address.data !== null ?  address : item.attributes.alternate_address }
                </span>
                </div>,
              no_of_items: item.attributes.order_items.data.length,
              single_delivery: item.attributes.delivery_type === 'final_delivery' ? 'Yes' : 'No',
              total_cost: <div className="price">
                <span className="span">€</span><span>{formatPrice(item.attributes.total_sum)}</span>
                </div>,
              actions: (
                <div style={{ display: 'flex' }}>
                  <CustomTooltip title={t('View')} style={{ marginTop:'-5px', marginBottom:'-5px'}}>
                  <IconButton color="primary"             
                  onClick={() => this.navigateToQuoteDetails(item.id, 'SAVED_QUOTE', this.state.savedQuotesData,
                   localStorage.setItem("UPDATE",'DETAILS'), 
                   localStorage.setItem('DEFAULT_TYPE',item.attributes.delivery_type === "final_delivery"? "FINAL_DELIVERY_ONLY": "PARTIAL_DELIVERY"),
                   //@ts-ignore
                   item?.attributes?.address?.data?.id,
                   item.attributes.alternate_address)}>
                    <FileCopyOutlined />
                  </IconButton>
                  </CustomTooltip>
                  <CustomTooltip title={t('Edit')} style={{ marginTop:'-5px', marginBottom:'-5px'}}>
                  <IconButton color="primary" test-id="navigateQuote">
                    <EditOutlined 
                    onClick={() => this.navigateToQuoteDetails(item.id, 'SAVED_QUOTE', this.state.savedQuotesData,
                     localStorage.setItem("UPDATE",'UPDATE'),
                     localStorage.setItem('DEFAULT_TYPE',item.attributes.delivery_type === "final_delivery"? "FINAL_DELIVERY_ONLY": "PARTIAL_DELIVERY"),
                    //@ts-ignore
                    item?.attributes?.address?.data?.id,
                    item.attributes.alternate_address)} />
                  </IconButton>

                  </CustomTooltip>
                  <CustomTooltip title={t('Deletebtn')} style={{ marginTop:'-5px', marginBottom:'-5px'}}>
                  <IconButton color="default">
                    <DeleteQuoteDialog onFormSubmit={() => this.deleteQuote(item.id)} t={t} />
                  </IconButton>
                  </CustomTooltip>
                </div>
              ),
            }})}
          />
        );
      } else if (this.state.savedQuotesError){
        return t(configJSON.noQuotesFound) 
      } else {
       return <div className="loading-section" data-testid="orders-loading">
        <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
      </div>
      }
    };

    const renderConfirmedQuotesTable = (state:any, t:any, configJSON:any) => {
      const { confirmedQuotes } = state;
      if (confirmedQuotes !== null && Array.isArray(confirmedQuotes) && confirmedQuotes.length > 0 ) {
      return (
        <Table
          stickyHeader
          className="confirmed-quotes-table"
          titles={ConfirmedQuotesTableName.map((table:any) => t(table.title))}
          items={confirmedQuotes?.map((item:any) =>{
            const expirationDate =  new Date (item.quote.expiration_date)
            const currentDate = new Date ()
            const timeDifference = expirationDate.getTime() - currentDate.getTime();
            const daysDifference = Math.ceil(timeDifference / (24 * 60 * 60 * 1000));
            const options = {
              day: '2-digit' as const,
              month: '2-digit' as const,
              year: 'numeric' as const,
              hour: '2-digit' as const,
              minute: '2-digit' as const,
              second: '2-digit' as const,
              hour12: true as const
            };

            const formattedDatetime = new Date(item?.quote?.quote_order_items[0]?.created_at).toLocaleString('en-GB', options);
            const dateTimeParts = (formattedDatetime.toUpperCase()).replace(/,/g, '').split(' ');
            const date = dateTimeParts[0];
            const time = dateTimeParts[1] + ' ' + dateTimeParts[2];
            const address =`${item?.address?.address_citya} ${item?.address?.address_numbera} ${item?.address?.address_countya} ${item?.address?.address_statea} ${item?.address?.address_postcodea}`;
            return {
            cart_id: item.quote.id,
            order_date: <>{date}<br />{time}</>,
            delivery_address:  
            <div className="shipping-address-item">
              <span>
              {item.address !== null ? address: item.quote.alternate_address}
              </span>
              </div>,            
            no_of_items: item.quote.quote_order_items.length,
            total_cost: <div className="price">
              <span className="span">€</span><span>{formatPrice(item.quote.total_sum)}</span>
              </div>,
            single_delivery: item.quote.single_delivery === 1 ? 'Yes' : 'No',
            to_ship: item.quote.to_ship,
            quote_id: item.quote.quote_code,
            status:  item.quote.quote_status,
            expiry_date: daysDifference === 1  ? new Date (item.quote.expiration_date).toLocaleDateString('en-GB'): t("Expired") ,
            actions: (
              <div className="actions">
                <CustomTooltip title={t('View')} style={{ marginTop:'-5px', marginBottom:'-5px'}}>
                <IconButton color="primary"  onClick={() => this.navigateToConffirmQuoteDetails(item.quote.id, 'CONFIRMED_QUOTE', this.state.confirmQuotesData,
                 localStorage.setItem("UPDATE",'CONFIRM_VIEW'), 
                 localStorage.setItem('DEFAULT_TYPE',item.quote.single_delivery=== 1 ? "FINAL_DELIVERY_ONLY": "PARTIAL_DELIVERY"),
                  item?.address?.id,
                  item.quote.alternate_address,
                  item.quote.total_sum
                 )}>
                  <FileCopyOutlined />
                </IconButton>
                </CustomTooltip>
                <CustomTooltip title={t('Search')} style={{ marginTop:'-5px', marginBottom:'-5px'}}>
                <IconButton color="primary">
                  <FindInPageOutlined color="primary" style={{ width: 30, height: 30 }} />
                </IconButton>
                </CustomTooltip>
              </div>
            ),
          }})}
        />
      );
    } else if(this.state.confirmedQuotesError) {
      return t(configJSON.noQuotesFound);
    } else {
      return <div className="loading-section" data-testid="orders-loading">
      <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
      </div>
    }
    };
     // Customizable Area End  

    return (
      // Customizable Area Start
      <MainLayout
      id="quote-management"
      navigation={this.props.navigation}
    >
     {(() => {
      switch (true) {
        case this.state.view !== "LIST" && localStorage.getItem("UPDATE") === 'UPDATE':
          return (
            <>
              {renderQuoteDetailsUpdate(this.state)}
            </>
          );
        case this.state.view !== "LIST" && localStorage.getItem("UPDATE") === 'DETAILS':
          return (
            <>
              {renderQuoteDetailsView(this.state)}
            </>
          );
        case this.state.view !== "LIST" && localStorage.getItem("UPDATE") === 'CONFIRM_VIEW':
          return (
            <>
              {renderConfirmQuoteDetailsView(this.state)}
            </>
          );
        default:
          return renderDefaultView(this.state, t, configJSON);
      }
    })()}   
    </MainLayout>
      // Customizable Area End
    );
    
  }
}

// Customizable Area Start
export default withTranslation()(Quotemanagement)
// Customizable Area End
