import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import Dialog from './Dialog.web';
import { withTranslation } from 'react-i18next';

export class ProductQuickView extends React.Component<IProps, IState> {
    private dialogRef = React.createRef<Dialog>();

    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
    }

    openDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
            this.dialogRef.current.openDialog();
        }
    }

    render() {
        const {t} = this.props
        return (
            <>
                <IconButton onClick={this.openDialog}>
                    <InfoRounded />
                </IconButton>

                <Dialog
                    ref={this.dialogRef}
                    id="product-quick-view-dialog"
                >
                    <div className="product-id-section">
                        <Typography component="strong">{t("ProductID")}:</Typography>
                        <Typography component="span">{this.props.id}</Typography>
                    </div>

                    <div className="product-details-section">
                        <div className="section-item">
                            <Typography component="strong">{t("ProductName")}:</Typography>
                            <Typography component="b">{this.props.name}</Typography>
                        </div>

                        <div className="section-item">
                            <Typography component="strong">{t("Description")}:</Typography>
                            <Typography component="span">{this.props.description}</Typography>
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }
}

interface IProps {
    id: string | number | null,
    name: string,
    description: string,
    t?:any
}

interface IState {}

export default withTranslation()(ProductQuickView);