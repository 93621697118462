import React from 'react';
import { Grid, GridProps, Button } from '@material-ui/core';
import { HomeOutlined, ShoppingCartOutlined, AllInboxOutlined, LocalShippingOutlined, AccountCircleOutlined, ShoppingBasketOutlined } from '@material-ui/icons';
import { classNames, clearHash, clearQueryParams } from './utilities/utils';
import MergeEngineUtility from '../../blocks/utilities/src/MergeEngineUtilities';
import { withTranslation } from 'react-i18next';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const MENU_ITEMS: IMenuItem[] = [
    { icon: <HomeOutlined />, title: 'Home', route: 'LandingPage' },
    { icon: <ShoppingCartOutlined />, title: 'Quote', route: 'Quotemanagement' },
    { icon: <AllInboxOutlined />, title: 'Orders', route: 'OrderManagement' },
    { icon: <LocalShippingOutlined />, title: 'Shipments&Documents', route: 'ShipmentDocument' },
    { icon: <AccountCircleOutlined />, title: 'Profile', route: 'Customisableuserprofiles2' },
    { icon: <ShoppingBasketOutlined />, title: 'Bestseller', route: 'Cfbestsellerfeature' },
]

class Sidebar extends React.Component<IProps, IState> {
      constructor(props: Readonly<IProps>) {
        super(props);
        this.state = {
            isSidebarOpen: false,
        };
      }
    onItemClicked(item: IMenuItem) {
        if (
            window
            && window.location.hash.length > 0
            && (
                window.location.pathname === `/${item.route}`
                || window.location.search.indexOf(item.route) > -1
            )
        ) {
            clearHash();
        }

        if (
            window
            && window.location
            && window.location.search.length > 0
            && (
                window.location.pathname === `/${item.route}`
                || window.location.search.indexOf(item.route) > -1
            )
        ) {
            clearQueryParams();
        }

        MergeEngineUtility.navigateToScreen(item.route, this.props, { navigateFrom: 'sidebar' })
    }
    onToggleSidebar = () => {
        this.setState((prevState) => ({
          isSidebarOpen: !prevState.isSidebarOpen,
        }));
    };

    render() {
        const { t } = this.props;
        const { isSidebarOpen } = this.state;
        return (
            <Grid {...this.props} item component="aside" className={`${isSidebarOpen ? 'open' : 'closed'}`}>
                <div className= "menu-items">
                  <Button className={`${isSidebarOpen ? "toggle-button-cross-icon" : "toggle-button"}`} onClick={this.onToggleSidebar}>
                      {isSidebarOpen ? <CloseIcon color='primary'/> : <MenuIcon />}
                  </Button>
                    {MENU_ITEMS.map((item) => (
                        <Button
                            key={item.route}
                            className={classNames({
                                '__is-active': item.route === window.location.pathname.substring(1)
                            })}
                            startIcon={item.icon}
                            fullWidth
                            color={item.route === window.location.pathname.substring(1) ? 'primary' : 'default'}
                            onClick={() => this.onItemClicked(item)}
                        >
                    {
                               item.title==="Shipments&Documents"?
                   <>
                   {
                   localStorage.getItem('language')==='en'?
                   <div className="shipments-item"> Shipments &<span>Documents</span></div>
                   :
                   <div className="shipments-item"> Spedizioni e<span>Documenti</span></div>
                   }                            
                   </>
                             :
                             <span>{t(item.title)}</span>
                            }                          
                        </Button>
                    ))}
                </div>
            </Grid>
        )
    }
}

interface IProps extends GridProps {
    navigation: any,
    t:any
}

interface IState {
    isSidebarOpen:any
}

interface IMenuItem {
    icon: JSX.Element,
    title: string | JSX.Element,
    route: string
}
export default withTranslation()(Sidebar)