import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from "react-i18next";
import TableWeb from './Table.web';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '20px'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface IProps {
    t?:any,
    onDeleteQuote?: () => void,
    countNullCatalogue?:any,
    activeOrder?:any,
    notNullCatalogue?:any
  }
  const CustomizedDialogs: React.FC<IProps> = ({t,onDeleteQuote,countNullCatalogue,activeOrder,notNullCatalogue}) => {
  const isOpen = localStorage.getItem('open')
  const [open, setOpen] = React.useState(isOpen === 'false' ? false: true);

  const goBack= () =>{
    setOpen(false);
    localStorage.setItem("open","false")
  }

  const ActiveOrdersTableTitle = [
    "ProductID",
    "ProductName",
    "Sub-Category"
  ];
  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle  id="customized-dialog-title">
        <Typography variant="h3" component="h3">
           {t("Deleted")}
       </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography style={{marginBottom:"25px"}}>
            {
              activeOrder?.length === 1 || notNullCatalogue.length ===0 ? t("ProductDeleted"): t("ProductDeletedMultiple")
            }         
          </Typography> 

          <TableWeb
            stickyHeader
            className="orders-products-table"
            titles={ActiveOrdersTableTitle}
            items={countNullCatalogue?.map((item: any) => {
            return {
            product_id: item.attributes.static_product_id,
            product_name: item.attributes.static_product_name,
            sub_category: item.attributes.static_sub_category,           
              };
            })}
          />
             
        </DialogContent>
        <DialogActions>
          {
            activeOrder?.length === 1 || notNullCatalogue.length ===0?
          <Button onClick={onDeleteQuote} variant="contained" color='primary'>
            {t("OK")}
          </Button>:
          <Button onClick={()=> goBack()} variant="contained"  color='primary'>
           {t("OK")}
          </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withTranslation()(CustomizedDialogs);