Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.SUB_CATEGORIES = [
  { categoryImage: 'https://picsum.photos/300/300', name: 'ACCESSORIES' },
  { categoryImage: 'https://picsum.photos/200/300', name: 'ACCESSORIES FOR FLANGES' },
  { categoryImage: 'https://picsum.photos/300/300', name: 'FIXING ARTICLES' },
  { categoryImage: 'https://picsum.photos/300/300', name: 'STAINLESS STEEL FRAMES AND SHEET' },
]

exports.CATEGORIES = [
  { categoryImage: 'https://picsum.photos/200/300', name: 'ACCESSORIES', subCategories: exports.SUB_CATEGORIES },
  { categoryImage: 'https://picsum.photos/200/300', name: 'ACCESSORIES FOR FLANGES', subCategories: exports.SUB_CATEGORIES },
  { categoryImage: 'https://picsum.photos/200/300', name: 'FIXING ARTICLES', subCategories: exports.SUB_CATEGORIES },
  { categoryImage: 'https://picsum.photos/200/300', name: 'STAINLESS STEEL FRAMES AND SHEET', subCategories: exports.SUB_CATEGORIES },
  { categoryImage: 'https://picsum.photos/200/300', name: 'ARTICLES FOR WELDING', subCategories: exports.SUB_CATEGORIES },
  { categoryImage: 'https://picsum.photos/200/300', name: 'STAINLESS MODULAR SYSTEM FOR FURNISHING', subCategories: exports.SUB_CATEGORIES },
  { categoryImage: 'https://picsum.photos/200/300', name: 'FITTINGS FOR FUEL AND GPL', subCategories: exports.SUB_CATEGORIES },
  { categoryImage: 'https://picsum.photos/200/300', name: 'STAINLESS FITTINGS CHEMICAL-FOOD PLANTS', subCategories: exports.SUB_CATEGORIES },
  { categoryImage: 'https://picsum.photos/200/300', name: 'POLYETILENE PIPES AND FITTINGS', subCategories: exports.SUB_CATEGORIES },
  { categoryImage: 'https://picsum.photos/200/300', name: 'FORGET FITTINGS SW-THD-BW', subCategories: exports.SUB_CATEGORIES },
];

exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.patchApiMethod = 'PATCH';
exports.apiHeader = { "Content-Type": 'application/json; charset=utf-8' };

exports.fetchCategoriesMethod = 'bx_block_categories/categories#index';
exports.fetchSubCategoriesMethod = 'bx_block_categories/sub_categories';
exports.fetchNotificationsApiCallId = 'bx_block_notifications/notifications';
// Customizable Area End