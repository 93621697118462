import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '20px'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface IProps {
    t?:any
  }
  const CustomizedDialogs: React.FC<IProps> = ({t}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const goBack= () =>{
    window.location.href="/Quotemanagement"
  }

  return (
    <div>
      <Button variant="contained" style={{ width: "300px"}} color="primary" onClick={handleClickOpen}>
        {t("Expired")}
      </Button>
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle  id="customized-dialog-title">
        <Typography variant="h3" component="h3">
           {t("Expired")}
       </Typography>
        </DialogTitle>
        <DialogContent >
          <Typography>
          {t("ExpiredQuoteText")}
          </Typography>          
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={()=> goBack()} color="primary">
           {t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withTranslation()(CustomizedDialogs);