// @ts-nocheck
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationITA from "../../components/src/common/ita.json";
import translationEN from "../../components/src/common/en.json";

const lang = localStorage.getItem("language");

const options = {
  resources: {
    en: {
      translation: translationEN,
    },
    it: {
      translation: translationITA,
    },
  },
  lng: lang?.toString(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).init(options);

export default i18n;
