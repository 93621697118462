import React from 'react';
//Customizable Area Start
import { Card, CardActionArea, CardMedia, CardContent, Typography, Grid, Chip, Zoom } from '@material-ui/core';
import LanguageHelper from '../../../components/src/utilities/LanguageHelper';
import { classNames } from '../../../components/src/utilities/utils';
// Customizable Area Start
import { defaultImage } from './assets';
// Customizable Area End
class Categories extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.onItemClicked = this.onItemClicked.bind(this);
    }

    onItemClicked(category: ICategory, idx?: number) {
        this.props.onItemClicked(category);
    }

    render() {
        const { categories, selectedCategoryId } = this.props;

        if (!Array.isArray(categories)) { return null; }

        return (
            <Grid container spacing={2}>
                {categories.map((category, idx) => (
                    <Grid
                        item
                        key={idx}
                        className={`${classNames({
                            'category-item-sub': this.props.catogoryClassName || false,
                            'category-item': true,
                            '__is-chip': this.props.kind === 'CHIP'
                        })}`}
                    >
                        <Zoom in={this.props.kind === 'CHIP'}>
                            <Chip
                                label={LanguageHelper.getLangText(category.name)}
                                onClick={() => this.onItemClicked(category, idx)}
                                color="primary"
                                className={classNames({
                                    '__chip-item': true,
                                    '__is-selected': selectedCategoryId === category.id
                                })}
                            />
                        </Zoom>

                        {this.props.kind === 'CARD' && (
                            <Zoom in={this.props.kind === 'CARD'}>
                                <Card className={`category-card ${this.props.catogoryClassName ?'cattegory-sub-item':""}`} onClick={() => this.onItemClicked(category, idx)} elevation={0}>
                                    <CardActionArea>
                                     {/* Customizable Area Start */}
                                        <CardMedia
                                            image={category.image ? category.image : defaultImage}
                                            title={LanguageHelper.getLangText(category.name)}
                                        />
                                      {/* Customizable Area End */}

                                        <CardContent>
                                            <Typography component="strong" className="category-name">
                                                {LanguageHelper.getLangText(category.name)}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Zoom>
                        )}                        
                    </Grid>
                ))}
            </Grid>
        )
    }
}

interface IProps {
    onItemClicked: (category: ICategory, idx?: number) => void,
    categories: ICategory[],
    kind: 'CARD' | 'CHIP',
    selectedCategoryId?: string | number,
    // Customizable Area Start
    catogoryClassName?:boolean
    // Customizable Area End 
}

interface IState {

}

export interface ISubCategory {
    id: number,
    name: string,
    created_at: string,
    updated_at: string,
    parent_id?: null | number,
    rank: null | number
}

export interface ICategory {
    id: number,
    name: string,
    dark_icon: null | string,
    dark_icon_active: null | string,
    dark_icon_inactive: null | string,
    light_icon: null | string,
    light_icon_active: null | string,
    light_icon_inactive: null | string,
    rank: null | number,
    created_at: string,
    updated_at: string,
    sub_categories: ISubCategory[],
    // Customizable Area Start
    image: string,
    // Customizable Area End
}

export default Categories;
// Customizable Area End