import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  SafeAreaView
} from "react-native";

import { Divider, Grow, Typography ,CircularProgress } from '@material-ui/core';
import MainLayout from '../../../components/src/MainLayout.web';
import Categories, { ICategory } from './CategoriesWeb.web';
import {  withTranslation } from 'react-i18next';
import './landingpage.css';

// Customizable Area End

import LandingPageController, {
  Props
} from "./LandingPageController";

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { t } = this.props;
   return (
      <SafeAreaView style={styles.mainContainer}>
      {/* Customizable Area Start */}
      <MainLayout title={t('Categories')} navigation={this.props.navigation} id="landingpage">

          <Categories
              onItemClicked={this.onCategorySelected}
              categories={this.state.categories}
              kind={this.state.selectedCategoryId > -1 ? 'CHIP' : 'CARD'}
              catogoryClassName={true}
              selectedCategoryId={this.state.selectedCategoryId}
          /> 
      
      {
        this.state.sub_categories.length > 0 && this.state.loading ? (
          <div className="loading-section" data-testid="orders-loading">
            <CircularProgress color="primary" style={{ width: 64, height: 64, marginTop: '50px' }} />
          </div>
        ) : this.state.sub_categories.length > 0 ? (
          <Grow in={this.state.sub_categories.length > 0}>
            <div className="sub-category-section">
              <Divider className="divider" />
              <Typography variant="h1" component="h2" className="page-title">
                {t('SubCategories')}
              </Typography>
              <Categories
                categories={this.state.sub_categories as ICategory[]}
                onItemClicked={(category: ICategory) => this.onSubCategorySelected(category)}
                kind="CARD"
              />
            </div>
          </Grow>
        ) : this.state.isSubCategory === true  && !this.state.loading ? (
          <div className="sub-category-section">
            <Divider className="divider" />
            <Typography variant="h1" component="h2" className="page-title">
              {t('SubCategories')}
            </Typography>
            <span>{t('SubCategoriesNotfound')}</span>
          </div>
        ) : (
          null
        )
      }                  
        </MainLayout>
      {/* Customizable Area End */}
      </SafeAreaView>
    );
  }
}
export default withTranslation()(LandingPage);
// Customizable Area Start
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff"
  }
});
// Customizable Area End
