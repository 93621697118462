import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface IFormValues {
  new_password: string;
  confirm_password: string;
}

interface S {
  // Customizable Area Start
  new_password: string;
  confirm_password: string;
  token: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      new_password: "",
      confirm_password: "",
      token: "",
    };

    this.redirectToLoginPage = this.redirectToLoginPage.bind(this);

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const UrlQueryParms = window.location.search;
    const queryParams = new URLSearchParams(UrlQueryParms) || "";
    const token = queryParams.get("token");
    this.setState({ token: token || "" });
    // Customizable Area End
  }

  redirectToLoginPage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    this.send(msg);
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.resetPasswordCallId) {
        if (!responseJson.errors) {
          setTimeout(() => {
            this.redirectToLoginPage();
          }, 1500);
        }
      }
    }
    // Customizable Area End
  }

  ResetPassword = (values: IFormValues) => {
    const httpBody = {
      data: {
        token: this.state.token,
        new_password: values.new_password,
        confirm_password: values.confirm_password,
      },
    };

    const header = {
      "Content-Type": configJSON.resetPasswordApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resetPasswordPostApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}
