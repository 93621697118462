Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "quotemanagement";
exports.labelBodyText = "quotemanagement Body";

exports.btnExampleTitle = "CLICK ME";
exports.pageTitle = 'ConfirmedQuotes';
exports.savedQuotesPageTitle = 'SavedQuotes';
exports.fetchSavedQuotesMethod = "bx_block_shopping_cart/quote_management";
exports.getApiMethod = "GET";
exports.apiHeader = { "Content-Type": 'application/json; charset=utf-8' };
exports.noQuotesFound = 'noQuotesFound';
exports.deleteDialogText = 'DeleteDialogQuote';
exports.quoteDetailsApiMethod = 'bx_block_shopping_cart/orders';
exports.placeOrderButtonText = 'PlaceOrder';
exports.createOrderApiMethod = 'bx_block_shopping_cart/order_items';
exports.noCartFound = "NoCartFound"
exports.viewOrderText = 'ViewOrder';
exports.postApiMethod = 'POST';
exports.toBeShippedText = 'ToBeShipped';
exports.requestProductApiEndPoint = "bx_block_address/request_addresses";
exports.fetchAddressesMethod = 'bx_block_address/addresses';
exports.deleteApiMethod="DELETE";
exports.contentType = "application/json";
exports.needPartialDeliveryText = 'NeedPartialDeliveryText';
exports.needFinalDeliveryText = 'NeedFinalDeliveryText';
exports.patchApiMethod = "PATCH";
exports.submitOffersApiEndpoint = 'bx_block_order_management/quote_orders';
exports.placeOrderFromConfirmQuotesApiMethod = 'bx_block_shopping_cart/quote_place_orders'

// Customizable Area End