// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  CircularProgress
} from "@material-ui/core";
import {
  classNames,
  formatPrice,
} from "../../../components/src/utilities/utils";
import { withTranslation } from "react-i18next";
import Table from "../../../components/src/Table.web";
import { PlaceOrderDialog } from "./PlaceOrderDialog.web";
import  CustomizedDialogs  from "../../../components/src/ExpireDetailsDailog";

// Customizable Area End
const ConfirmQuoteDetailsView: React.FC<IProps> = ({
  loading,
  page,
  activeQuote,
  t,
  submitOrder,
  createNewAddress,
  view,
  submitOrderDialogClose,
  navigateToOrderDetails,
  createdOrderDetails,
  addresses,
  newAddressLoading,
  newAddressSubmitted,
  isOrderLoading,
  isOrderSubmitted,
  orderMethod,
  changeOrderMethod,

}) => {
  const [activeOrder, setActiveOrder] = useState([]);
  const [activeQuoteData, setActiveQuoteData] = useState({
    address:{
    id:"",
    address_citya: "",
    address_countya: "",
    address_namea: "",
    address_numbera: "",
    address_postcodea: "",
    address_statea: "",
    address_streeta: "",
    customer_code: ""} || null,
    
   quote:{
    id: 0,
    total_sum:0,
    customer_code:0,
    product_price:"",
    single_delivery:1,
    address_id:"",
    expiration_date:"",
    alternate_address:"",
    quote_order_items:[{created_at:''}]
   }
  });

  const tableTitles = React.useMemo(() => {
    const ActiveOrdersTableName = [
      "ProductName",
      "ProductID",
      "Order_Date",
      "Total_Cost",
      "Type",
      "To_Ship",
      "Delivery_Address",
      "Sub-Category",
      "cost"
    ];
    if (
      !Array.isArray(activeQuote?.quote?.quote_order_items) ||
      (Array.isArray(activeQuote?.quote?.quote_order_items) &&
      activeQuote?.quote?.quote_order_items === 0)
    ) {
      return ActiveOrdersTableName;
    }

    return ActiveOrdersTableName.map((title) => title);
  }, [activeQuote]);

  useEffect(() => {
    if (activeQuote?.quote?.quote_order_items) {
      setActiveOrder(activeQuote?.quote?.quote_order_items);
      const newArray = {
        products: {}
      };
    
      activeQuote?.quote?.quote_order_items?.forEach((item:any) => {
      const productId = item.product_id;
      const cartQuantity = item.quantity;
      //@ts-ignore
      newArray.products[`id_${productId}`] = {
        product_id: productId,
        cart_quantity: cartQuantity
      };
    });
    
    const dataToStore = {
      products: newArray.products,
      prices: {},
      totalPrice: 0,
      productDetails: {}
    };

    localStorage.setItem(`cartData`, JSON.stringify(dataToStore));
    }

   setActiveQuoteData(activeQuote);
   }, [activeQuote?.quote?.quote_order_item, activeQuote]);
     
    const expirationDate =  new Date (activeQuoteData?.quote?.expiration_date)
    const currentDate = new Date ()
    const timeDifference = expirationDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (24 * 60 * 60 * 1000));
  // Customizable Area End
  return (
    // Customizable Area Start
    <>
      {loading ? (
        <div data-testid="active-order-loading" className="loading-section">
          <CircularProgress style={{ width: 64, height: 64 }} color="primary" />
        </div>
      ) : (
        <div className="active-order-section">
          <Grid container spacing={3} className="top-section" >
            <Grid item component="div" className="order-details"  md={7}>           
                <Typography variant="h1" component="h1" className="page-title">
                  {t('ConfirmedQuotes')}
                </Typography>             
            
            <Typography>{new Date (activeQuoteData?.quote?.quote_order_items[0]?.created_at).toLocaleDateString('en-GB')}</Typography>
              <Typography
                data-testid="order-id-title"
                variant="h1"
                component="h1"
              >
                {t("Cart_ID")} {activeQuoteData?.quote?.id}
              </Typography>

              <Paper elevation={0} className="order-section-card">
                <div className="order-detail-item">
                  <Typography component="strong"  variant="subtitle1">
                    {t("Customer_ID")}
                  </Typography>
                  <span data-testid="customer-id">
                    {
                      activeQuoteData?.quote?.customer_code
                    }
                  </span>
                </div>

                <div className="order-detail-item">
                  <Typography component="strong" variant="subtitle1" >
                    {t("Order_Date")}
                  </Typography>
                  <span data-testid="order-date">-</span>
                </div>

                <div className="order-detail-item">
                  <Typography component="strong"  variant="subtitle1" >
                    {t("Total_Amount")}
                  </Typography>
                  <span data-testid="total-fees">
                    € {formatPrice(activeQuoteData?.quote?.total_sum)}
                  </span>
                </div>

                <div className="order-detail-item">
                  <Typography component="strong" variant="subtitle1" >
                    {t("Quote_Expiry")}
                  </Typography>
                  <span data-testid="order-date">{ daysDifference === 1? `${daysDifference} ${t('Day')}`: t('Expired')}</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Table
            stickyHeader
            className="orders-products-table"
            titles={tableTitles}
            items={activeOrder?.map((item: any) => {
              const address = `${activeQuoteData?.address?.address_citya}`+ " " +`${activeQuoteData?.address?.address_numbera}`+" "+`${activeQuoteData?.address?.address_countya}`+ " " +`${activeQuoteData?.address?.address_statea}`+ " " +`${activeQuoteData?.address?.address_postcodea}`;
             
              const options = {
                day: '2-digit' as const,
                month: '2-digit' as const,
                year: 'numeric' as const,
                hour: '2-digit' as const,
                minute: '2-digit' as const,
                second: '2-digit' as const,
                hour12: true as const
              };
  
              const formattedDatetime = new Date(activeQuoteData?.quote?.quote_order_items[0]?.created_at).toLocaleString('en-GB', options);
              const dateTimeParts = (formattedDatetime.toUpperCase()).replace(/,/g, '').split(' ');
              const date = dateTimeParts[0];
              const time = dateTimeParts[1] + ' ' + dateTimeParts[2];
              return {
                product_name: item.product_name,
                product_id: item.product_id,
                order_Date: <>{date}<br />{time}</>,               
                total_Cost: (
                  <div className="price">              
                      <span className="span">€</span><span>{formatPrice(item.product_price * item.quantity)}</span>
                  </div>
                ),
                type: activeQuoteData.quote.single_delivery === 1 ?"Fully":"Partially",
                to_ship: "Yes",
                Delivery_Address: activeQuoteData?.address !== null? address : activeQuote?.quote?.alternate_address,
                sub_category: item.product_sub_category,
                cost:formatPrice(item.product_price)
              };
            })}
          />
          <div
            className={classNames({
              "cart-table-footer": true,
              "__is-checkout": page === "CONFIRMED_QUOTE",
            })}
          >
            <strong className="total-cart-amount">
              {t("TotalQuoteAmount")}
            </strong>
            <span className="price">           
              <span className="span"> € </span>
              <span>{formatPrice(activeQuoteData?.quote?.total_sum)}
              </span>                                  
            </span>
          </div>
          <div className="button-group">
            <>
              {
                daysDifference===1?
                <PlaceOrderDialog
                page={page}
                t={t}
                view={view}
                isLoading={Object.keys(loading).length > 0 || isOrderLoading}
                isSubmitted={isOrderSubmitted}
                submitOrder={submitOrder}
                onDialogClose={submitOrderDialogClose}
                addresses={addresses}
                createdOrderDetails={createdOrderDetails}
                navigateToOrderDetails={navigateToOrderDetails}
                createNewAddress={createNewAddress}
                createNewAddressLoading={newAddressLoading}
                isRequestAddressSubmitted={newAddressSubmitted}
                orderMethod={orderMethod}
                changeOrderMethod={changeOrderMethod}
                default_delivery_type={activeQuoteData?.quote?.single_delivery === 1 ? "FINAL_DELIVERY_ONLY": "PARTIAL_DELIVERY"}
                default_address_id={activeQuoteData?.address?.id.toString()}
                default_address={activeQuoteData?.quote?.alternate_address}
                />:
              //@ts-ignore
             <CustomizedDialogs t={t}/>
              }
              
            </>
          </div>
        </div>
      )}
    </>

    // Customizable Area End
  );
};

// Customizable Area Start

interface IProps {
  page: "SAVED_QUOTE" | "CONFIRMED_QUOTE";
  activeQuote: any;
  t: any;
  loading: boolean;
  id: any;
  submitOrder?: (addressId: string, alternateAddress?: string) => void;
  view: "LIST" | "DETAILS";
  createNewAddress: (address: string) => void;
  submitOrderDialogClose: any;
  navigateToOrderDetails: any;
  createdOrderDetails: any;
  addresses: any;
  newAddressLoading: boolean;
  newAddressSubmitted: boolean;
  isOrderLoading: boolean;
  isOrderSubmitted: boolean;
  quantityUpdate: any;
  changeOrderMethod: (method: any) => void;
  orderMethod?: 'PARTIAL_DELIVERY' | 'FINAL_DELIVERY_ONLY',
  fetchQuoteDetail?:(quote_id: any)=>void;
}

interface IState {}

export default withTranslation()(ConfirmQuoteDetailsView);
// Customizable Area End
