import React, { ReactElement } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

interface CustomTooltipProps {
  title: string;
  children: ReactElement;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  style?:any
}

const useStyles = makeStyles(() => ({
    customTooltip: {
      color: '#ffff',
      fontSize: '14px',
      fontWeight: 500,
      marginTop:'-5px',
      marginBottom:'-5px'
    },
  }));
  
const CustomTooltip: React.FC<CustomTooltipProps> = ({style, title, children, placement = 'bottom' }) => {
   const classes = useStyles();
  return (
    <Tooltip title={title} placement={placement} classes={{ tooltip: classes.customTooltip }} style={style} >
      {children}
    </Tooltip>
  );
};

export default withTranslation()(CustomTooltip);
