import React from 'react';
 // Customizable Area Start
import { Button, CircularProgress, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LanguageHelper from "../../../components/src/utilities/LanguageHelper";
import Dialog from '../../../components/src/Dialog.web';
import Input from '../../../components/src/Input.web';
import { isValidEmail } from '../../../components/src/utilities/utils';

const configJSON = require("./config");
 // Customizable Area End
class ForgotPasswordDialog extends React.Component<IProps, IState> {
     // Customizable Area Start
    private dialogRef = React.createRef<Dialog>();

    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onEmailInputBlur = this.onEmailInputBlur.bind(this);
        this.onEmailInputFocus = this.onEmailInputFocus.bind(this);
        this.getResetLink = this.getResetLink.bind(this);

        this.state = { emailValue: '' }
    }

    openDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
            this.dialogRef.current.openDialog();
        }
    }

    closeDialog(isSelfClose = false) {
        this.setState({
            emailValue: '',
            errorMessage: '',
        }, () => {
            this.props.onDialogClose();

            if (isSelfClose && this.dialogRef.current && typeof this.dialogRef.current.closeDialog === 'function') {
                this.dialogRef.current.closeDialog();
            }
        });
    }

    onEmailInputBlur(event: React.ChangeEvent<HTMLInputElement>) {
        const val = event.target.value;
        const res = isValidEmail(val);

        if (res) {
            return this.setState({ emailValue: event.target.value });
        }

        this.setState({
            errorMessage: configJSON.emailErrorMessage
        });
    }

    onEmailInputFocus() {
        if (this.state.errorMessage) {
            this.setState({ errorMessage: '' });
        }
    }

    getResetLink() {
        if (this.state.errorMessage) { return; }
        if (!this.state.emailValue) {
            return this.setState({ errorMessage: configJSON.emailErrorMessage })
        }

        this.props.onFormSubmit(this.state.emailValue);
    }
     // Customizable Area End
    render() {
         // Customizable Area Start
        return (
            <>
                <Button
                    variant="text"
                    className="forgot-password-btn"
                    onClick={this.openDialog}
                >
                    {LanguageHelper.getLangText(configJSON.forgotPasswordButtonText)}
                </Button>

                <Dialog
                    id="forgot-password-dialog"
                    title={this.props.isSubmitted ? 'Success!' : 'Forgot Password'}
                    ref={this.dialogRef}
                    closable
                    onClose={this.closeDialog}
                    disablePortal
                >
                    {this.props.isSubmitted ? (
                        <div className="submitted-view">
                            <CheckCircleIcon className="check-icon" />

                            <Typography variant="body2" className="description">
                                {LanguageHelper.getLangText(configJSON.forgotPasswordResetSuccessText)}
                            </Typography>

                            <Button
                                className="rounded-button"
                                variant="contained"
                                color="primary"
                                onClick={() => this.closeDialog(true)}
                                fullWidth
                            >
                                {LanguageHelper.getLangText(configJSON.forgotPasswordResetOkText)}
                            </Button>
                        </div>
                    ) : (
                        <div className="form-view">
                            {this.props.loading === true ? (
                                <div className="loading-section">
                                    <CircularProgress
                                        color="primary"
                                        style={{ width: 64, height: 64 }}
                                    />
                                </div>
                            ) : (
                                <>
                                    <Typography variant="body2" className="forgot-password-description">
                                        {LanguageHelper.getLangText(configJSON.forgotPasswordResetLinkDescription)}
                                    </Typography>

                                    <Input
                                        type="email"
                                        label={LanguageHelper.getLangText(configJSON.emailFieldLabelName)}
                                        onBlur={this.onEmailInputBlur}
                                        onFocus={this.onEmailInputFocus}
                                        errorMessage={<span>{LanguageHelper.getLangText(this.state.errorMessage!)}</span>}
                                        fullWidth
                                    />

                                    <Button
                                        className="rounded-button"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.getResetLink}
                                        fullWidth
                                    >
                                        {LanguageHelper.getLangText(configJSON.forgotPasswordGetResetLinkButtonText)}
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </Dialog>
            </>
        )
         // Customizable Area End
    }
};
 // Customizable Area Start
interface IProps {
    onFormSubmit: (email: string) => void,
    onDialogClose: () => void,
    loading?: boolean,
    isSubmitted?: boolean
}

interface IState {
    emailValue: string,
    errorMessage?: string,
}

export default ForgotPasswordDialog;
 // Customizable Area End