import React, { useState } from 'react';
// Customizable Area Start
import {
    Button,
    Backdrop,
    Menu,
    MenuItem,
    Radio,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Dialog,
    DialogContent,
    TextField
} from "@material-ui/core";
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { FILTER_VALUE, INNER_FILTER_VALUE } from './types';
import { IAddress } from '../../../components/src/types/types';
import { mergeAddress } from '../../../components/src/utilities/utils';
import { withTranslation } from 'react-i18next';
import { ErrorMessage, Formik, Field} from "formik";
import * as Yup from "yup";
import { configJSON } from './OrderDetails';
// Customizable Area End
// Customizable Area Start
const OrderManagementFilter: React.FC<IProps> = ({
    filterValue,
    onFilterValueChanged,
    addresses,
    t,
    alternateAddress
}) => {
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [innerView, setInnerView] = useState<INNER_FILTER_VALUE | null>(null);
    const [orderStatus, setOrderStatus] = useState<string | null>(null);
    const [deliveryAddress, setDeliveryAddress] = useState<number | null>(null);
    const [alternateAddres, setAlternateAddres] = useState<string | null>(null);

    const onFilterClicked = (event: React.MouseEvent<HTMLElement>) => {
        setFilterAnchorEl(event.currentTarget as any);
    }

    const onFilterMenuClosed = () => {
        setInnerView(null);
        setFilterAnchorEl(null);
    }

    const onValueChanged = (value: FILTER_VALUE) => {
        if (filterValue === value) {
            onFilterValueChanged(null);
            return onFilterMenuClosed();
        }

        if ((['CUSTOM_DATES', 'ORDER_STATUS', 'DELIVERY_ADDRESS','ALTERNATE_ADDRESS','DELIVERY_ADDRES'] as INNER_FILTER_VALUE[]).includes(value as INNER_FILTER_VALUE)) {
            setInnerView(value as INNER_FILTER_VALUE)
        } else {
            onFilterValueChanged(value)   
        }
    }

    const onOrderStatusSubmit = () => {
        onFilterValueChanged('ORDER_STATUS', orderStatus)
        onFilterMenuClosed()
    }

    const customDatesFormSubmit = (values:any) => {
        onFilterValueChanged('CUSTOM_DATES', values)
        onFilterMenuClosed()
    }

    const onDeliveryAddressSubmit = () => {
        onFilterValueChanged('DELIVERY_ADDRESS', deliveryAddress)
        onFilterMenuClosed()
    }
    const onAlternateAddressSubmit = () => {
        onFilterValueChanged('ALTERNATE_ADDRESS', alternateAddres)
        onFilterMenuClosed()
    }
    const language = localStorage.getItem('language');
    const validationMessage = language === 'en'
    ? configJSON.EndDateValidationMsg
    : configJSON.EndDateValidationMsgIt;
    const validationMessageForStartDate = language === 'en'
    ? configJSON.RequiredSartDate
    : configJSON.RequiredSartDateIt;
    const validationMessageForEndDate = language === 'en'
    ? configJSON.RequiredEndDate
    : configJSON.RequiredEndDateIt;

    const validationSchema = Yup.object({
        startDate: Yup.date().required(validationMessageForStartDate),
        endDate: Yup.date()
            .required(validationMessageForEndDate)
            .when('startDate', (startDate:any, schema:any) => {
                return startDate
                    ? schema.min(startDate,validationMessage)
                    : schema;
            }),
    });
   // Customizable Area End
    return (
        // Customizable Area Start
        <>        
            <Button
                variant="text"
                onClick={onFilterClicked}
                data-testid="filter-button"
                color={!!filterValue ? 'primary' : 'default'}
            >
                <span className="button-text">{t('Filter')}</span>
                <FilterListOutlinedIcon />
            </Button>

            <Backdrop open={Boolean(filterAnchorEl)} style={{ zIndex: 999999999999 }}>
                <Menu
                    className="filter-menu"
                    data-testid="filter-menu"
                    anchorEl={filterAnchorEl}
                    keepMounted
                    open={Boolean(filterAnchorEl)}
                    onClose={onFilterMenuClosed}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem data-testid="order-status" onClick={() => onValueChanged('ORDER_STATUS')}><FormControlLabel label={t("OrderStatus")}control={<Radio checked={filterValue === 'ORDER_STATUS'} />} /></MenuItem>
                    <MenuItem data-testid="custom-dates" onClick={() => onValueChanged('CUSTOM_DATES')}><FormControlLabel label={t("CustomDates") }control={<Radio checked={filterValue === 'CUSTOM_DATES'} />} /></MenuItem>
                    <MenuItem data-testid="delivery-addresses" onClick={() => onValueChanged('DELIVERY_ADDRESS')}><FormControlLabel label={t("DeliveryAddresses")} control={<Radio checked={filterValue === 'DELIVERY_ADDRESS'} />} /></MenuItem>
                    <MenuItem onClick={() => onValueChanged('PARTIAL_DELIVERY')}><FormControlLabel label={t("PartialDelivery")} control={<Radio checked={filterValue === 'PARTIAL_DELIVERY'} />} /></MenuItem>
                    <MenuItem onClick={() => onValueChanged('FULL_DELIVERY')}><FormControlLabel label={t("FullDelivery")} control={<Radio checked={filterValue === 'FULL_DELIVERY'} />} /></MenuItem>
                </Menu>
            </Backdrop>


            <Dialog
                className="filter-inner-view-dialog"
                data-testid="filter-inner-view-dialog"
                onClose={onFilterMenuClosed}
                open={innerView !== null}
                BackdropProps={{
                    style: { backgroundColor: 'rgba(0,0,0,0.3)' }
                }}
                PaperProps={{
                    className: 'custom-dialog-wrapper',
                    elevation: 5,
                }}
            >
                <DialogContent>
                    {innerView === 'ORDER_STATUS' && (
                        <div className="order-statuses">
                            <FormControl>
                                <RadioGroup aria-labelledby="order-statuses" onChange={(event) => setOrderStatus(event.target.value)}>
                                    <FormControlLabel value="1" control={<Radio checked={orderStatus === '1'} />} label={t('OrderConfirmed')} />
                                    <FormControlLabel value="2" control={<Radio checked={orderStatus === '2'} />} label={t('Preperation')} />
                                    <FormControlLabel value="3" control={<Radio checked={orderStatus === '3'} />} label={t('Shipped')} />
                                    <FormControlLabel value="4" control={<Radio checked={orderStatus === '4'} />} label={t('Delivered')} />
                                    <FormControlLabel value="5" control={<Radio checked={orderStatus === '5'} />} label={t('OrderRejected')} />
                                </RadioGroup>
                            </FormControl>

                            <div className="actions">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onFilterMenuClosed}
                                    fullWidth
                                >
                                    {t('Cancel')}
                                </Button>

                                <Button
                                    data-testid="filter-trigger-button"
                                    variant="contained"
                                    color="primary"
                                    disabled={!orderStatus}
                                    fullWidth
                                    onClick={onOrderStatusSubmit}
                                >
                                    {t('Filter')}
                                </Button>
                            </div>
                        </div>
                    )}

                    {innerView === 'CUSTOM_DATES' && (
                        
                        <Formik
                            data-testid="custom-dates-form"
                            initialValues={{ startDate: '', endDate: '' }}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                customDatesFormSubmit(values)
                            }}
                        >
                             {({ values, handleChange, handleSubmit }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                                className="custom-dates-form"              
                            >
                             <Field
                                type="date"
                                id="start-date"
                                name="startDate"
                                label={t('StartDate')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                as={TextField}
                            />
                            <ErrorMessage name="startDate" component="div" className="error-message" />

                              <Field
                                type="date"
                                id="end-date"
                                name="endDate"
                                label={t('EndDate')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                as={TextField} 
                            />
                             <ErrorMessage name="endDate" component="div" className="error-message" />

                            <div className="actions">
                                <Button
                                    data-testid="cancel-button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={onFilterMenuClosed}
                                    fullWidth
                                >
                                    {t('Cancel')}
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={!values.startDate || !values.endDate}
                                    fullWidth
                                    data-testid="filter-button"
                                >
                                    {t('Filter')}
                                </Button>
                            </div>
                            </form>
                          )}
                        </Formik>
                    )}

                    {innerView === 'DELIVERY_ADDRESS' && (
                        <div className="delivery-addresses">
                              <MenuItem data-testid="delivery-addresses" onClick={() => onValueChanged('DELIVERY_ADDRES')}><FormControlLabel label={t("DeliveryAddresses")} control={<Radio checked={filterValue === 'DELIVERY_ADDRES'} />} /></MenuItem>
                              <MenuItem data-testid="delivery-addresses" onClick={() => onValueChanged('ALTERNATE_ADDRESS')}><FormControlLabel label={t("AlternateAddress")} control={<Radio checked={filterValue === 'ALTERNATE_ADDRESS'} />} /></MenuItem>
                        </div>
                    )}

                    {innerView === 'ALTERNATE_ADDRESS' && (
                        <div className="delivery-addresses">
                            <FormControl>
                                <RadioGroup aria-labelledby="order-alternate-addresses" onChange={(event) => setAlternateAddres(event.target.value)}>                                
                                  {alternateAddress.filter((item) => 
                                    //@ts-ignore
                                    !!item.alternate_address).map((addressItem) => (
                                        <FormControlLabel 
                                            //@ts-ignore                                     
                                            key={addressItem.alternate_address}  
                                             //@ts-ignore                                         
                                            value={addressItem.alternate_address}
                                            //@ts-ignore
                                            control={<Radio checked={alternateAddres !== null && addressItem !== null && alternateAddres === addressItem.alternate_address} />}
                                            //@ts-ignore
                                            label={addressItem.alternate_address}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                 
                            <div className="actions">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onFilterMenuClosed}
                                    fullWidth
                                >
                                    {t('Cancel')}
                                </Button>

                                <Button
                                    data-testid="filter-trigger-button"
                                    variant="contained"
                                    color="primary"
                                    disabled={!alternateAddres}
                                    fullWidth
                                    onClick={onAlternateAddressSubmit}
                                >
                                    {t('Filter')}
                                </Button>
                            </div>
                             
                        </div>
                    )}
                     {innerView === 'DELIVERY_ADDRES' && (
                        <div className="delivery-addresses">
                            <FormControl>
                                <RadioGroup aria-labelledby="order-alternate-addresses" onChange={(event) => setDeliveryAddress(parseInt(event.target.value))}>                                
                                     {addresses.filter((item) => !!item.address_citya).map((addressItem) => (
                                        <FormControlLabel
                                            key={addressItem.id}
                                            value={addressItem.id}
                                            control={<Radio checked={deliveryAddress !== null && addressItem !== null && deliveryAddress === addressItem.id} />}
                                            label={mergeAddress(addressItem)}
                                        />
                                       ))}
                                </RadioGroup>
                            </FormControl>
                 
                            <div className="actions">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onFilterMenuClosed}
                                    fullWidth
                                >
                                    {t('Cancel')}
                                </Button>

                                <Button
                                    data-testid="filter-trigger-button"
                                    variant="contained"
                                    color="primary"
                                    disabled={!deliveryAddress}
                                    fullWidth
                                    onClick={onDeliveryAddressSubmit}
                                >
                                    {t('Filter')}
                                </Button>
                            </div>
                             
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
        // Customizable Area End
    )
}
// Customizable Area Start
export interface IProps {
    filterValue: FILTER_VALUE | null,
    onFilterValueChanged: (value: IProps['filterValue'], innerValue?: any) => void,
    addresses: (IAddress & { id: number })[],
    t?:any,
    alternateAddress:(IAddress & { id: number })[],
}

export default withTranslation()(OrderManagementFilter);
// Customizable Area End