
export default class LanguageHelper {
    static _TEXTS = {
        en: {
          
        },
        tr: {
    
        }
    };

    static getLangText(text: string) {
        return text;
    }
}