import React from 'react';
import { Button, Typography, CircularProgress, ButtonProps } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import Dialog from './Dialog.web'
import Input from './Input.web'
import { withTranslation } from 'react-i18next';


export class RequestNewAddress extends React.Component<IProps, IState> {
    private dialogRef = React.createRef<Dialog>();

    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.enterAddress = this.enterAddress.bind(this);
        this.onAddressInputBlur = this.onAddressInputBlur.bind(this);
        this.state = { address: '', isAddressError: false }
    }

    openDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
            this.dialogRef.current.openDialog();
        }
    }

    closeDialog(isSelfClose = false) {
        this.setState({
            address: '',
        }, () => {
            this.props.closeDialog();

            if (isSelfClose) {
                this.dialogRef.current?.closeDialog();
            }
        });
    }

    onAddressInputBlur(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ address: event.target.value, isAddressError: false });
    }

    enterAddress() {
        if (!this.state.address) {
            return this.setState({ isAddressError: true });
        }

        this.props.formSubmit(this.state.address);
    }

    render() {
        const {t} = this.props
        return (
            <>  
                <Button
                    className="address-request"
                    variant="contained"
                    color="primary"
                    {...(this.props.buttonProps || {})}
                    onClick={() => this.openDialog()}
                >
                    {t('RequestToNewAddress')}
                </Button>

                <Dialog
                    id="request-new-address-dialog"
                    title={t(this.props.isSubmitted ? 'Success' : 'NewAddress')}
                    ref={this.dialogRef}
                    onClose={this.closeDialog}
                    closable
                >
                    {this.props.isSubmitted ? (
                        <div className="new-address-success-section">
                            <CheckCircle className="check-icon" />

                            <Typography variant="body2" className="description">
                                {t('RequestPlaced')}
                            </Typography>

                            <Button
                                className="rounded-button"
                                variant="contained"
                                color="primary"
                                onClick={() => this.closeDialog(true)}
                                fullWidth
                            >
                                {t('OK')}
                            </Button>
                        </div>
                    ) : (
                        <div className="new-address-section">
                            {this.props.loading === true ? (
                                <div className="loading-section">
                                    <CircularProgress
                                        color="primary"
                                        style={{ width: 64, height: 64 }}
                                    />
                                </div>
                            ) : (
                                <>
                                    <Typography variant="body1" className="description">
                                        {t('SubmitMassage')}
                                    </Typography>

                                    <Input
                                        type="textarea"
                                        label={t('EnterAddress')}
                                        onBlur={this.onAddressInputBlur}
                                        errorMessage={this.state.isAddressError ? <span>{t('ValidAddress')}</span> : ''}
                                        fullWidth
                                    />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.enterAddress}
                                        fullWidth
                                    >
                                        {t('Submit')}
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </Dialog>
            </>
        )
    }
}

interface IProps {
    loading?: boolean,
    isSubmitted?: boolean,
    formSubmit: (address: string) => void,
    closeDialog: () => void,
    buttonProps?: ButtonProps,
    t?:any
}

interface IState {
    address: string,
    isAddressError: boolean
}

export default withTranslation()(RequestNewAddress);