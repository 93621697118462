// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import { IActiveQuoteData } from "./types";
import {
  classNames,
  formatPrice,
} from "../../../components/src/utilities/utils";
import { withTranslation } from "react-i18next";
import Table from "../../../components/src/Table.web";
import { PlaceOrderDialog } from "./PlaceOrderDialog.web";
import { DeleteQuoteDialog } from "./DeleteQuoteDialog.web";
import Input from "../../../components/src/Input.web";
import { DeleteOutline } from "@material-ui/icons";
import { ConfirmQuoteDialog } from "./ConfirmQuoteDialog.web";
import DeleteProductBackendDailog from "../../../components/src/DeleteProductBackendDailog";

// Customizable Area End
const QuoteDetailsUpdate: React.FC<IProps> = ({
  loading,
  page,
  activeQuote,
  t,
  submitOrder,
  createNewAddress,
  view,
  submitOrderDialogClose,
  navigateToOrderDetails,
  createdOrderDetails,
  addresses,
  newAddressLoading,
  newAddressSubmitted,
  isOrderLoading,
  isOrderSubmitted,
  quantityUpdate,
  orderMethod,
  changeOrderMethod,
  deleteQuoteItem,
  cartData,
  fetchCartOnEveryUpdate,
  submitOffer,
  DeleteQuote
}) => {
  const [activeOrder, setActiveOrder] = useState([]);
  const [activeQuoteData, setActiveQuoteData] = useState({
    id: "",
    attributes: {
      customer: { data: { attributes: { customer_code: "" } } },
      total_amount: "",
      total_sum:"",
      delivery_type:"",
      address:{data:{attributes: { 
      address_citya: "",
      address_countya: "",
      address_namea: "",
      address_numbera: "",
      address_postcodea: "",
      address_statea: "",
      address_streeta: "",
      customer_code: "" },id:0,}},
      alternate_address:""
    },
  });
  const [ catalogueDataUpdate , setCatalogueDataUpdate] = useState([]);
  const [event, setEvent] = useState(0);
  const [productIdData, setProductID] = useState(0);
  const tableTitles = React.useMemo(() => {
    const ActiveOrdersTableName = [
      "ProductID",
      "ProductName",
      "Sub-Category",
      "Quantity",
      "cost",
      "Delivery_Address",
      "",
    ];
    if (
      !Array.isArray(activeQuote?.attributes?.order_items?.data) ||
      (Array.isArray(activeQuote?.attributes?.order_items?.data) &&
        activeQuote?.attributes?.order_items?.data === 0)
    ) {
      return ActiveOrdersTableName;
    }

    return ActiveOrdersTableName.map((title) => title);
  }, [activeQuote?.attributes?.order_items?.data]);


  useEffect(() => {
    if (activeQuote?.attributes?.order_items?.data !== undefined) {
      setActiveOrder(activeQuote?.attributes?.order_items?.data);
      const newArray = {
        products: {},
        prices: {},
        totalPrice: 0,
        productDetails: {}
      };
    
    activeQuote?.attributes?.order_items?.data.forEach((item:any) => {
      const productId = item?.attributes?.catalogue?.data?.attributes?.product_id;
      const cartQuantity = event? event : item.attributes.quantity;
      //@ts-ignore
      newArray.products[`id_${productId}`] = {
        ...cartQuantity,
        product_id: productId,
        cart_quantity: productIdData === productId ? cartQuantity : item.attributes.quantity
      };  
       //@ts-ignore
       newArray.productDetails[`id_${productId}`] = item?.attributes?.catalogue?.data?.attributes; 
       setCatalogueDataUpdate(item?.attributes?.catalogue?.data?.attributes)
    });
    
    const dataToStore = {
      products: newArray.products,
      prices: {},
      totalPrice: 0,
      productDetails: newArray.productDetails
    };
    
    localStorage.setItem(`cartData`, JSON.stringify(dataToStore));
    }

    if (activeQuote?.attributes !== undefined) {
      setActiveQuoteData(activeQuote);
    }
    
  }, [activeQuote?.attributes?.order_items?.data, activeQuote,event]);

  const countNullCatalogueUpdate = activeOrder.filter(
    (Item:any) => 
      Item.attributes.catalogue.data === null
  );
  const notNullCatalogueUpdate = activeOrder.filter(
    (orderItem:any) => 
      orderItem.attributes.catalogue.data !== null
  );

  // Customizable Area End
  return (
    // Customizable Area Start

    <>
     {
     countNullCatalogueUpdate?.length >= 1 ? 
      <DeleteProductBackendDailog
      //@ts-ignore
       t={t}
       countNullCatalogue={countNullCatalogueUpdate}
       activeOrder= {activeOrder}
       onDeleteQuote={() => {
        DeleteQuote( window.location.hash.substring(1)) 
      }} 
      notNullCatalogue={notNullCatalogueUpdate}                     
       /> : ''
    }
      {loading ? (
        <div className="loading-section" data-testid="active-order-loading">
          <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
        </div>
      ) : (
        <div className="active-order-section">
          <Grid container className="top-section" spacing={3}>
            <Grid item className="order-details" component="div" md={7}>
            <Typography variant="h1" className="page-title" component="h1">
                  {t('SavedQuotes')}
            </Typography>
              <Typography
                variant="h1"
                component="h1"
                data-testid="order-id-title"
              >
                {t("Cart_ID")} {activeQuoteData.id}
              </Typography>
              <Paper elevation={0} className="order-section-card">
                <div className="order-detail-item">
                  <Typography variant="subtitle1" component="strong">
                    {t("Customer_ID")}
                  </Typography>
                  <span data-testid="customer-id">
                    {
                      activeQuoteData.attributes.customer.data.attributes
                        .customer_code
                    }
                  </span>
                </div>

                <div className="order-detail-item">
                  <Typography variant="subtitle1" component="strong">
                    {t("Order_Date")}
                  </Typography>
                  <span data-testid="order-date">-</span>
                </div>

                <div className="order-detail-item">
                  <Typography variant="subtitle1" component="strong">
                    {t("Total_Amount")}
                  </Typography>
                  <span data-testid="total-fees">
                    € {formatPrice(activeQuoteData.attributes.total_sum)}
                  </span>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Table
            stickyHeader
            className="orders-products-table"
            titles={tableTitles}
            items={notNullCatalogueUpdate?.map((item: any) => {
              const address = `${activeQuoteData?.attributes?.address?.data?.attributes?.address_citya}`+ " " +`${activeQuoteData.attributes?.address?.data?.attributes?.address_numbera}`+" "+`${activeQuoteData?.attributes?.address?.data?.attributes?.address_countya}`+ " " +`${activeQuoteData.attributes?.address?.data?.attributes?.address_statea}`+ " " +`${activeQuoteData?.attributes?.address?.data?.attributes?.address_postcodea}`;
              const pid = `id_${item?.attributes?.catalogue?.data?.attributes?.product_id}`
              const isProductExist = !!cartData.products[pid];
              const productQuantity = isProductExist ? cartData.products[pid].cart_quantity : 1;
              return {
                product_id:
                  item?.attributes?.catalogue?.data?.attributes?.product_id?item?.attributes?.catalogue?.data?.attributes?.product_id:item.attributes.static_product_id,
                product_name: item?.attributes?.catalogue?.data?.attributes?.name?item?.attributes?.catalogue?.data?.attributes?.name:item.attributes.static_product_name,
                sub_category:
                  item?.attributes?.catalogue?.data?.attributes?.sub_category?.name?item?.attributes?.catalogue?.data?.attributes?.sub_category?.name :item.attributes.static_sub_category,
                quantity: (
                  <div className="quantity-input">
                    <Input
                        defaultValue={isProductExist ? productQuantity :  item.attributes.quantity}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                          quantityUpdate(item?.attributes?.catalogue?.data?.attributes?.product_id, parseInt(event.target.value));
                          setEvent(parseInt(event.target.value));
                          setProductID(item?.attributes?.catalogue?.data?.attributes?.product_id)
                        }}
                        type="number"
                        disabled={item.attributes.catalogue.data===null? true: false}
                    />
            
                    {(item?.attributes?.catalogue?.data?.attributes?.base_unit?item?.attributes?.catalogue?.data?.attributes?.base_unit:item.attributes.static_base_unit)}.
                  </div>
                ),
                total_Cost: (
                  <div className="price">
                     <span className="span">€</span><span>{formatPrice(item.attributes.total_cost)}</span>
                  </div>
                ),               
                Delivery_Address: activeQuoteData?.attributes?.address?.data!== null? address : activeQuoteData?.attributes.alternate_address,
                actions: (
                  <div className="actions">
                    {item.attributes.catalogue.data===null?                    
                    <IconButton className="delete-button">
                    <DeleteOutline />
                    </IconButton>                              
                    : 
                    <DeleteQuoteDialog
                    t={t}
                    onFormSubmit={() => {
                      deleteQuoteItem(item.id)                      
                    }}                    
                  />}
                   
                  </div>
                ),
              };
            })}
          />
          <div
            className={classNames({
              "cart-table-footer": true,
              "__is-checkout": page === "SAVED_QUOTE",
            })}
          >
            <strong className="total-cart-amount">
              {t("TotalQuoteAmount")}
            </strong>
            <span className="price">
              € {formatPrice(activeQuoteData.attributes.total_sum)}
            </span>
          </div>
          <div className="button-group">
            <>
            
            {event!== 0 ?
             <Button variant="contained" color="primary" onClick={fetchCartOnEveryUpdate}>
              {t("SavedQuotes")}             
            </Button> :
            <Button variant="outlined" color="primary" disabled={catalogueDataUpdate === undefined && activeOrder.length===1|| notNullCatalogueUpdate.length ===0}>
                {t("EditQuote")}
              </Button>
            }            
            {
             catalogueDataUpdate === undefined && activeOrder.length===1 || notNullCatalogueUpdate.length ===0 ? 
             <Button variant="outlined" color="primary" disabled fullWidth>
             {t("ConfirmedQuotes")}
           </Button>:
                <ConfirmQuoteDialog
                page={page}
                t={t}
                view={view}
                isLoading={Object.keys(loading).length > 0 || isOrderLoading}
                isSubmitted={isOrderSubmitted}
                submitOrder={submitOffer}
                onDialogClose={submitOrderDialogClose}
                addresses={addresses}
                createdOrderDetails={createdOrderDetails}
                navigateToOrderDetails={navigateToOrderDetails}
                createNewAddress={createNewAddress}
                createNewAddressLoading={newAddressLoading}
                isRequestAddressSubmitted={newAddressSubmitted}
                orderMethod={orderMethod}
                changeOrderMethod={changeOrderMethod}
                default_delivery_type={activeQuoteData?.attributes.delivery_type === "final_delivery"? "FINAL_DELIVERY_ONLY": "PARTIAL_DELIVERY"}
                default_address_id={activeQuoteData?.attributes?.address?.data?.id.toString()}
                default_address={activeQuoteData?.attributes?.alternate_address}
             />
            }

           {
             catalogueDataUpdate === undefined && activeOrder.length===1 || notNullCatalogueUpdate.length ===0? 
             <Button variant="outlined" color="primary" disabled fullWidth>
             {t("PlaceOrder")}
           </Button>:
              <PlaceOrderDialog
                page={page}
                t={t}
                view={view}
                isLoading={Object.keys(loading).length > 0 || isOrderLoading}
                isSubmitted={isOrderSubmitted}
                submitOrder={submitOrder}
                onDialogClose={submitOrderDialogClose}
                addresses={addresses}
                createdOrderDetails={createdOrderDetails}
                navigateToOrderDetails={navigateToOrderDetails}
                createNewAddress={createNewAddress}
                createNewAddressLoading={newAddressLoading}
                isRequestAddressSubmitted={newAddressSubmitted}
                orderMethod={orderMethod}
                changeOrderMethod={changeOrderMethod}
                default_address_id={activeQuoteData?.attributes?.address?.data?.id.toString()}
                default_address={activeQuoteData?.attributes.alternate_address}
              />
            }
            </>
          </div>
        </div>
      )}
    </>
    // Customizable Area End
  );
};

// Customizable Area Start

interface IProps {
  page: "SAVED_QUOTE" | "CONFIRMED_QUOTE" ;
  activeQuote: IActiveQuoteData;
  t: any;
  loading: any;
  id: any;
  submitOrder?: (addressId: string, alternateAddress?: string) => void;
  view: "LIST" | "DETAILS" | 'UPDATE';
  createNewAddress: (address: string) => void;
  submitOrderDialogClose: any;
  navigateToOrderDetails: any;
  createdOrderDetails: any;
  addresses: any;
  newAddressLoading: boolean;
  newAddressSubmitted: boolean;
  isOrderLoading: boolean;
  isOrderSubmitted: boolean;
  quantityUpdate: any;
  orderMethod:any;
  changeOrderMethod: (method: any) => void;
  cart?: any;
  deleteQuoteItem:any;
  quantity:number;
  product_id:any;
  submitOffer:(addressId: string, alternateAddress?: string, deliveryType?:string) => void;
  cartData:any;
  fetchCartOnEveryUpdate:()=>void;
  DeleteQuote:(quote_id:any)=>void;
  isOfferLoading:boolean;
}

interface IState {}

export default withTranslation()(QuoteDetailsUpdate);
// Customizable Area End
