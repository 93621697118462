import React from 'react';
// Customizable Area Start
import { Button, Typography, IconButton } from '@material-ui/core';
import { DeleteOutline } from "@material-ui/icons";
import Dialog from '../../../components/src/Dialog.web';
import { withTranslation } from "react-i18next";

const configJSON = require("./config");
 // Customizable Area End

export class DeleteCartItemDialog extends React.Component<IProps, IState> {
    // Customizable Area Start
    private dialogRef = React.createRef<Dialog>();

    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.state = {}
    }

    openDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
            this.dialogRef.current.openDialog();
        }
    }

    closeDialog() {
        if (this.dialogRef.current && typeof this.dialogRef.current.closeDialog === 'function') {
            this.dialogRef.current.closeDialog();
        }
    }
 // Customizable Area End
    render() {
        // Customizable Area Start
        const { t }=this.props
        return (
            <>
                <IconButton className="delete-button" onClick={this.openDialog}>
                    <DeleteOutline color="error" />
                </IconButton>

                <Dialog
                    id="delete-cart-item-dialog"
                    ref={this.dialogRef}
                    closable={false}
                >
                    <div className="dialog-content">
                        <Typography component="strong">{t(configJSON.deleteDialogText)}</Typography>

                        <div className="button-group">
                            <Button variant="contained" size="large" onClick={this.props.onFormSubmit}>{t('Delete')}</Button>

                            <Button
                                variant="outlined"
                                size="large"
                                onClick={this.closeDialog}
                            >
                                {t('Cancel')}
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </>
        )
        // Customizable Area End
    }
}
 // Customizable Area Start
interface IProps {
    onFormSubmit: () => void,
    onDialogClose?: () => void,
    t?:any
}

interface IState {}

export default withTranslation()(DeleteCartItemDialog);
// Customizable Area End