 // Customizable Area Start
 import React, { PropsWithChildren } from 'react';
 import { Typography } from '@material-ui/core';
 import { classNames } from '../../../components/src/utilities/utils';
 // Customizable Area End

class ProfileFormItem extends React.Component<PropsWithChildren<IProps>, IState> {
    // Customizable Area Start
     render() {
        let { label, children, className, labelIcon = null } = this.props;

        return (
            <div
                className={classNames({
                    'item': true,
                    [className]: !!className
                })}
            >
                <Typography component="label" variant="subtitle1">
                    {label}
                    {labelIcon}
                </Typography>

                <div className="value">
                    {children}
                </div>
            </div>
        )
    }
    // Customizable Area End
}
 // Customizable Area Start
interface IProps {
    label: string,
    labelIcon?: React.ReactNode,
    className: string
}

interface IState {}

export default ProfileFormItem;
 // Customizable Area End