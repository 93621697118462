import React, { useState } from 'react';
// Customizable Area Start
import {
    Button,
    Backdrop,
    Menu,
    MenuItem,
    Radio,
    FormControlLabel
} from "@material-ui/core";
import SortOutlinedIcon from '@material-ui/icons/SortOutlined';
import { SORT_VALUE } from './types';
import { withTranslation } from 'react-i18next';
// Customizable Area End
// Customizable Area Start
const OrderManagementSort: React.FC<IProps> = ({
    sortValue,
    onSortValueChanged,
    t
}) => {
    const [sortAnchorEl, setSortAnchorEl] = useState(null);

    const onSortClicked = (event: React.MouseEvent<HTMLElement>) => {
        setSortAnchorEl(event.currentTarget as any);
    }

    const onSortMenuClosed = () => {
        setSortAnchorEl(null);
    }

    const onValueChanged = (value: SORT_VALUE) => {
        onSortValueChanged(value);
    }

    return (
        <>        
            <Button
                variant="text"
                onClick={onSortClicked}
                data-testid="sort-button"
                color={(sortValue && sortValue !== 'ALL_ORDERS') ? 'primary' : 'default'}
            >
                <span className="button-text">{t('Sort')}</span>
                <SortOutlinedIcon />
            </Button>

            <Backdrop open={Boolean(sortAnchorEl)} style={{ zIndex: 999999 }}>
                <Menu
                    className="sort-menu"
                    data-testid="sort-menu"
                    anchorEl={sortAnchorEl}
                    keepMounted
                    open={Boolean(sortAnchorEl)}
                    onClose={onSortMenuClosed}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => onValueChanged('ALL_ORDERS')}><FormControlLabel label={t('All_Orders')} control={<Radio checked={sortValue === 'ALL_ORDERS' || sortValue === null} />} /></MenuItem>
                    <MenuItem onClick={() => onValueChanged('VIEW_LAST_WEEK')}><FormControlLabel label={t('View_last_week')} control={<Radio checked={sortValue === 'VIEW_LAST_WEEK'} />} /></MenuItem>
                    <MenuItem onClick={() => onValueChanged('VIEW_LAST_MONTH')}><FormControlLabel label={t('View_last_month')} control={<Radio checked={sortValue === 'VIEW_LAST_MONTH'} />} /></MenuItem>
                </Menu>
            </Backdrop>
        </>
    )
}

export interface IProps {
    sortValue: SORT_VALUE | null,
    onSortValueChanged: (value: IProps['sortValue']) => void,
    t?:any
}

export default withTranslation()(OrderManagementSort);
// Customizable Area End