import React from 'react';
// Customizable Area Start
import { Button, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Input from '../../../components/src/Input.web';
import Table from '../../../components/src/Table.web';
import ProductQuickView from '../../../components/src/ProductQuickView.web';
import { ICart, ICartProduct, IProduct } from '../../../components/src/types/types'
import { debounce, formatPrice } from '../../../components/src/utilities/utils';
import { BestSellarsTitles } from '../../../components/src/tableNameConstants';
import { withTranslation } from 'react-i18next';
// Customizable Area End

export class BestSellerProductsTable extends React.Component<IProps, IState> {
    // Customizable Area Start
    private scrollFn: any = null;

    constructor(props: IProps) {
        super(props);

        this.scrollTable = this.scrollTable.bind(this);
        this.getTableTitles = this.getTableTitles.bind(this);
        this.addProductToCart = this.addProductToCart.bind(this);
        this.changeProductQuantity = this.changeProductQuantity.bind(this);

        this.scrollFn = debounce(this.scrollTable, 300)

        this.state = {
            // @ts-ignore
            clickedProductId: -1,
            productQuantities: {} as IState['productQuantities']
        }
    }

    componentDidMount() {
        const { cart } = this.props;
        const quantities: Record<string, number> = {}

        Object.entries(cart.products).forEach(([product_id, product]: [string, any]) => {
            if (!product || !product.cart_quantity) { return; }
            quantities[product_id] = product.cart_quantity;
        })

        this.setState({ productQuantities: quantities })
    }

    addProductToCart(productId: ICartProduct['product_id']) {
        let { productQuantities } = this.state;
        const { cart } = this.props;
        const key = `id_${productId}`

        const qty = productQuantities[key];
        if (!qty && qty !== 0 || (qty === 0 && !cart.products[`id_${productId}`])) {
            const quantityInput = document.querySelector(`[data-pid="${productId}"] input`) as HTMLInputElement;
            if (quantityInput) {
                return quantityInput.focus();
            }
        }

        if (typeof qty !== 'string') {
            this.setState({ clickedProductId: productId });


            setTimeout(() => {
                this.props.onCartItemUpdate(productId, qty, 'UPDATE');
                // @ts-ignore
                this.setState({ clickedProductId: -1 });
            }, 1000);
        }
    }

    changeProductQuantity(product_id: number, qty: number) {
        const { productQuantities } = this.state;
        const key = `id_${product_id}`;

        if (qty < 0 || Number.isNaN(qty)) {
            productQuantities[key] = 0;
        } else {
            productQuantities[key] = qty;
        }

        this.setState({ productQuantities })
    }

    scrollTable(event: any) {
        const el = document.querySelector('.pantalone-table');
        if (!el) { return; }

        if (this.props.isNewProductsLoading) { return; }

        if (el.scrollHeight - el.scrollTop <= 700) {
            this.props.fetchProducts((this.props.products.length / 30) + 1);
        }
    }

    getTableTitles() {
        const { products ,t} = this.props;   

        if (products.length > 0) {
            const isAllRowsSame = products.every((product) => product.base_unit === products[0].base_unit);
            BestSellarsTitles[4] = isAllRowsSame ? `${t("price")}/${products[0].base_unit}` : t("Price/Unit");
        }

        return BestSellarsTitles.map((titles:any)=>t(titles));
    }
    // Customizable Area End  
    render() {
        // Customizable Area Start
        const { products ,t } = this.props;        

        return (
            <Table
                className="bestsellers-table"
                stickyHeader
                tableContainerProps={{
                    onScroll: this.scrollFn
                }}
                titles={this.getTableTitles()}
                items={products.map((item) => {
                    const qty = this.state.productQuantities[`id_${item?.product_id}`];
                    return {
                      id: (
                        <div className="id-item">                           
                          {item?.product_id?item?.product_id:item?.static_product_id}
  
                          {item?.catalogue!== null? <ProductQuickView id={item?.product_id} name={item?.name} description={localStorage.getItem('language') === "en" ? item?.description : item?.alternate_description} />:<ProductQuickView id={item?.static_product_id} name={item?.static_product_name} description={item?.static_product_description} />}
                        </div>
                      ),
                      name: item?.name? item?.name: item?.static_product_name,
                      category: item?.category?.name?item?.category?.name:item?.static_category ,
                      subcategory_name: item?.sub_category?.name?item?.sub_category?.name:item?.static_sub_category,
                      price: (
                        <div className="price">
                          <span className="span">€</span><span>{formatPrice(item?.price)}</span>
                        </div>
                      ),
                      last_purchase: (
                        <span className="last-purchase">
                            {new Date((item?.updated_at || '')).toLocaleDateString('en-GB')}
                        </span>
                      ),
                      quantity: (
                        <div className="quantity-input" data-pid={item?.product_id}>
                            <Input
                                value={qty}
                                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    // @ts-ignore
                                    this.changeProductQuantity(item?.product_id, parseInt(event.target.value))
                                }}
                                disabled={item?.catalogue!==null? false : true}
                            />
                            {item.base_unit?item.base_unit:item.static_base_unit}
                        </div>
                        ),
                      actions: (                        
                        <Button
                          className="reorder-button"
                          color="primary"
                          variant="contained"
                          fullWidth                          
                          onClick={() => this.addProductToCart(item?.product_id)}
                          disabled={item?.catalogue!==null? false : true}
                        >
                         {t("Reorder")}
  
                          {item.product_id === this.state.clickedProductId && (
                            <span className="icon-area">
                                <CheckCircleIcon className="check-circle-icon" />
                            </span>
                          )}
                        </Button>
                      )
                  }})}
            >
                {this.props.isNewProductsLoading && (
                    <div className="loading-section">
                        <CircularProgress
                            color="primary"
                            style={{ width: 48, height: 48 }}
                        />
                    </div>
                )}
            </Table>
        )
    }
        // Customizable Area End
}
// Customizable Area Start
interface IProps {
    products: IProduct[],
    onCartItemUpdate: (productId: ICartProduct['product_id'], qty: number, operation: 'UPDATE' | 'DELETE') => void,
    fetchProducts: (pageNumber: number) => void,
    cart: ICart,
    isNewProductsLoading: boolean,
    t?:any
}
interface IState {
    clickedProductId: IProduct['product_id']
    productQuantities: Record<string, number | string>
}

export default withTranslation()(BestSellerProductsTable);
// Customizable Area End