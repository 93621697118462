Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.apiHeader = { "Content-Type": 'application/json; charset=utf-8' };
exports.catalogueApiEndPoint = "bx_block_catalogue/discounts_catalogue";
exports.requestProductApiEndPoint = "bx_block_catalogue/catalogues_request";
exports.searchCatalogueApiEndpoint = 'bx_block_catalogue/catalogues_search';

exports.requestProductDialogText = 'requestProductDialogText';
exports.requestProductDialogEmailLabel = 'requestProductDialogEmailLabel';
exports.requestProductDialogButtonText = 'SubmitRequest';
exports.requestProductDialogErrorMessage = 'requestProductDialogErrorMessage';
exports.requestProductDialogProductNotFoundText = 'ProductNotFound';
exports.requestProductDialogRequestProductText = 'RequestProduct';
exports.requestProductDialogSuccessText = 'ProductRequestPlaced';
exports.requestProductDialogSuccessButton = 'OK';

exports.addToCartText = 'AddToCart';
exports.updateCartText = 'UpdateCart';

// Customizable Area End