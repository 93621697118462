Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "documentstorage";
exports.labelBodyText = "documentstorage Body";

exports.btnExampleTitle = "CLICK ME";

exports.shipmentDocumentData = [
  {
    order_id: '25671231',
    DDT_id:"DDT765745",
    delivery_date: "2023-02-10T05:48:59.059Z",
    shipping_address:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    invoice_id: "INV434343",    
    order_date: '2023-05-10T05:48:59.059Z',
    customer_fullname:"Sean Johnson"
  },
  {
    order_id: '25676431',
    DDT_id:"DDT795740",
    delivery_date: "2023-02-10T05:48:59.059Z",
    shipping_address:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    invoice_id: "INV434343",    
    order_date: '2023-05-10T05:48:59.059Z',
    customer_fullname:"Sean Johnson"
  }
];
// Customizable Area End